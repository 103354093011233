import React,{useState} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
const Paginationcustom=({data,changepage,currentPage,setCurrentPage})=>{	
	return(
		<>
			<Stack spacing={2}>		     
		      <Pagination count={data} page={currentPage} onChange={changepage} variant="outlined" color="secondary" />		     
		    </Stack>
		</>
		)
}

export default Paginationcustom;