import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Loader from '../../loader/loader';
const Summary=({periodic,summary_details,from_date,to_date,loading,summaryData,filterBy,periodicExportData,exportPeriodicReportDetails})=>{

	const [currentPage, setCurrentPage] = useState(summaryData!=null?summaryData.page:1);
    // const [recordsPerPage] = useState(summaryData!=null?summaryData.limit:null);
    /*--pagination start here---*/ 

    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = periodic;

    const nPages = summaryData!=null?summaryData.total_page_count:null;
    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);        
        filterBy(parseInt(p))           
    } 

	/*--pagination start end here---*/ 

let total_incoming_call = 0;
let total_outgoing_call=0;
let total_induration = 0;
let total_outduration = 0;
let total_missed_call = 0;
let total_missed_duration=0;
let total_call = 0;
let total_duration = 0;
let total_connected_call = 0;
let total_unique_clients = 0;

if(summary_details!==null || typeof summary_details	!=='undefined');
{
	let total_length = summary_details!=null?summary_details.length:0;
	for(let i=0;i<total_length;i++){
		total_call+=parseInt(summary_details[i].total_call);
		total_incoming_call+=parseInt(summary_details[i].total_incoming);
		total_outgoing_call+=parseInt(summary_details[i].total_outgoing);
		total_induration+=parseInt(summary_details[i].incoming_duration);
		total_outduration+=parseInt(summary_details[i].outgoing_duration);
		total_missed_call+=parseInt(summary_details[i].total_missed);		
		total_unique_clients+=parseInt(summary_details[i].unique_clients);		

	}
	total_duration=total_induration+total_outduration;

	total_connected_call=total_incoming_call+total_outgoing_call;
	
}

const dateDiffInDays= (a, b)=> {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24; 
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const a = new Date(from_date);
const b = new Date(to_date);
const diffDays = dateDiffInDays(a,b)+1;
periodic.map((item,index)=>{
	const total_incoming_outgoing = parseInt(item.total_incoming)+parseInt(item.total_outgoing);
	periodic[index]['average_incoming']=parseFloat(parseInt(item.total_incoming)/diffDays);
	periodic[index]['average_outgoing']=parseFloat(parseInt(item.total_outgoing)/diffDays);
	periodic[index]['total_average']=parseFloat(total_incoming_outgoing/diffDays);
})


	return (
		<>
			<div className="row">
				<div className="col-lg-6">
					<div className="row">
						<div className="col-sm-8">

							<table className="table table-bordered mt-4">
								<thead>
									<tr>
										<th>Call Type</th>
										<th>Calls</th>
										<th>Duration</th>
									</tr>
								</thead>
								<tbody>
								
									
									<tr>
										<td>Incoming</td>
										<td>{total_incoming_call}</td>
										<td>{total_induration} sec</td>
									</tr>
									<tr>
										<td>Outgoing</td>
										<td>{total_outgoing_call}</td>
										<td>{total_outduration} sec</td>
									</tr>
									<tr>
										<td>Missed</td>
										<td>{total_missed_call}</td>
										<td>{total_missed_duration} sec</td>
									</tr>
									<tr>
										<td>Rejected</td>
										<td>0</td>
										<td>0 sec</td>
									</tr>
								
								
								</tbody>
								<tfoot>
									<tr>
										<th>Total</th>
										<th>{total_call}</th>
										<th>{total_duration}</th>
									</tr>
								</tfoot>
							</table>
						</div>

						<div className="col-sm-4 mt-4">
							 <div className="card" style={{background:'#f53345',margin:'0px 0px 5px 0px'}}>
		                        <div className="card-body" style={{padding:'5px'}}>
		                            <div className="row">
		                                <div className="col-md-2">		                                    
		                                        <i className="iconly-boldCall-Missed" style={{color:'#fff',fontSize:'18px'}}></i>		                                  
		                                </div>
		                                <div className="col-md-10">
		                                    <h6  style={{color:'#fff',fontSize:'12px'}}>Never Attended</h6>
		                                    <h6 className="font-extrabold mb-0" style={{color:'#fff'}}>{total_missed_call}</h6>
		                                </div>
		                            </div>
		                        </div>
		                    </div>

		                   

		                    <div className="card" style={{background:'rgb(5 165 39 / 66%)',margin:'0px 0px 5px 0px'}}>
		                        <div className="card-body" style={{padding:'5px'}}>
		                            <div className="row">
		                                <div className="col-md-2 ">		                                   
		                                        <i className="fa fa-volume-control-phone icon-rotation" style={{color:'#fff',fontSize:'18px'}}></i>		                                   
		                                </div>
		                                <div className="col-md-10">
		                                    <h6 style={{color:'#fff',fontSize:'12px'}}>Connected Calls</h6>
		                                    <h6 className="font-extrabold mb-0" style={{color:'#fff'}}>{total_connected_call}</h6>
		                                </div>
		                            </div>
		                        </div>
		                    </div>

		                    <div className="card" style={{background:'rgb(9 84 253 / 45%)',margin:'0px 0px 5px 0px'}}>
		                        <div className="card-body" style={{padding:'5px'}}>
		                            <div className="row">
		                                <div className="col-md-2 ">		                                   
		                                        <i className="fa fa-user" style={{color:'#fff',fontSize:'18px'}}></i>		                                   
		                                </div>
		                                <div className="col-md-10">
		                                    <h6 style={{color:'#fff',fontSize:'12px'}}>Unique Clients</h6>
		                                    <h6 className="font-extrabold mb-0" style={{color:'#fff'}}>{total_unique_clients}</h6>
		                                </div>
		                            </div>
		                        </div>
		                    </div>


		                     <div className="card" style={{background:'rgb(225 218 4 / 83%)',margin:'0px 0px 5px 0px'}}>
		                        <div className="card-body" style={{padding:'5px'}}>
		                            <div className="row">
		                                <div className="col-md-2 ">		                                   
		                                        <i className="fa fa-clock-o" style={{color:'#fff',fontSize:'18px'}}></i>		                                   
		                                </div>
		                                <div className="col-md-10">
		                                    <h6 style={{color:'#fff',fontSize:'12px'}}>Working Hours</h6>
		                                    <h6 className="font-extrabold mb-0" style={{color:'#fff'}}>0</h6>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
						</div>
					</div> 

				</div>

				<div className="col-lg-6">
					   <Chart 
	                    series={[total_incoming_call,total_outgoing_call,total_missed_call]} 
	                    options={{
	                        labels:['Incoming call','Outgoing call','Never Attended']
	                    }}           
	                    type="pie"
	                    width="100%" height="100%"
	                ></Chart>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-12">
					<h6>Employee Summary ({summaryData && summaryData.total_data})</h6>
				</div>
				<div className="col-lg-12">
				<button						  
						  className="btn btn-success"
						  onClick={exportPeriodicReportDetails}
						>
				  <i className="fa fa-download"></i> Export
				</button> 
					<div className="table-responsive">

						<table className="table table-bordered">
							<thead>
								<tr>
									<th rowSpan="2" style={{width:"200px"}}>Agent name</th>
									<th rowSpan="2">Agent code</th>
									<th rowSpan="2">Assign mobile</th>
									<th rowSpan="2">Total Calls</th>
									<th rowSpan="2">Total duration(in seconds)</th>
									<th rowSpan="2">Working hourse</th>
									<th rowSpan="2">Unique Clients</th>
									<th rowSpan="2">Connected Calls</th>
									<th colSpan="2">Incomming</th>
									<th colSpan="2">Outgoing</th>
									<th rowSpan="2">Missed</th>
									<th rowSpan="2">ASD</th>
									<th rowSpan="2">ISR</th>
									
								</tr>
								<tr>
									<th>Call</th>
									<th>Duration(in seconds)</th>
									<th>Call</th>
									<th>Duration(in seconds)</th>
								</tr>

							</thead>
							<tbody>
							{
								loading==true?<><tr><td colSpan="15"><Loader/></td></tr></>:
              	<>
              	{
								periodic && periodic.map((item,index)=>(

								<tr>
									<td>{item.agent_name}</td>
									<td>{item.agent_code}</td>
									<td>{item.agent_contact_no}</td>
									<td>{item.total_call}</td>
									<td>{item.total_duration}</td>
									<td>0</td>
									<td>{item.unique_clients}</td>
									<td>{parseInt(item.total_incoming)+parseInt(item.total_outgoing)}</td>
									<td>{item.total_incoming}</td>
									<td>{item.incoming_duration}</td>
									<td>{item.total_outgoing}</td>
									<td>{item.outgoing_duration}</td>									
									<td>{item.total_missed}</td>
									<td>{item.ASD} %</td>
									<td>{item.ISR} %</td>
								
								</tr>
								))
							}
							</>
						}
							</tbody>
						</table>
					</div>
					 <div style={{textAlign:"right",float:"right"}}>
							 <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
           </div>
				</div>
			</div>
		</>
		)
} 


export default Summary; 