import ReactDOM,{useState,useEffect} from 'react';
import {Link,useNavigate,useLocation} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import axios from 'axios';
import {APP_URL,getdates} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
import { Editor } from '@tinymce/tinymce-react';
const AddTemplate=()=>{	
	const {search} = useLocation();
	const temp_id=new URLSearchParams(search).get("temp_id");
	const [tempid,setTempid]=useState(temp_id!='' || temp_id!=null?temp_id:'');	
	const [token,setToken]=useState(localStorage.getItem('token'));
	const [selectedemp,setSelectedemp]=useState('');
	const [loading,setLoading]=useState(false);
	const [highestData,setHighestData]=useState(null); 
	const [highestExportData,setHighestExportData]=useState([]);
	const [titleError,setTitleError]=useState(true);
	const [tagsError,setTagsError]=useState(true);
	const [typeError,setTypeError]=useState(true);
	const [subjectError,setSubjectError]=useState(true);
	const [descriptionError,setDescriptionError]=useState(true);
	const [tagslist,setTagslist]=useState([]);	
	const [templateDetails,setTemplateDetails]=useState({
		title:'',
		tags:0,
		template_type:0,
		subject:'',
		description:''
	});
	const {title,tags,template_type,subject,description}=templateDetails;
	let history = useNavigate();
	useEffect(()=>{
		setToken(localStorage.getItem('token'));
		loadtags();
		if(tempid!=null){
			template_details();	
		}
		
	},[])
	const handleAddVariable=(e)=>{
		window.tinymce.activeEditor.execCommand('mceInsertContent',false,e.target.textContent);   		
	}
	/*----------handle change-----*/
	const handleDescription=(e)=>{
		setTemplateDetails({...templateDetails,description:e.target.getContent()});
	}

	const handleTemplateChange=(e)=>{
		setTemplateDetails({...templateDetails,[e.target.name]:e.target.value});
	}

	/*-----------service tags------*/

	 const loadtags = async()=>{ 
	 	try{     
	        const company_list = await axios.get(APP_URL+'list-active-lead-tags',{ headers: {"Authorization" : `Bearer ${token}`}});
	        let companiesList = JSON.parse(JSON.stringify(company_list.data));
	        setTagslist(companiesList.data.lead_tags);
        }catch(e){
        	return false;
        }
             
    }

	/*-------------validate template fields-----------*/
	
	const ValidateInpFields = ()=>{
        if(title===''){
            setTitleError(false);
        }else{
            setTitleError(true);
        } 
        if(tags===0){
            setTagsError(false);
        }else{
            setTagsError(true);
        }
        if(template_type===0){
            setTypeError(false);
        }else{
            setTypeError(true);
        }

        if(subject===''){
            setSubjectError(false);
        }else{
            setSubjectError(true);
        } 
        if(description===''){
            setDescriptionError(false);
        }else{
            setDescriptionError(true);
        }        

        if(title==='' || tags===0 || template_type===0 || subject==='' || description===''){
            return false;
        }else{
            return true;
        }
    }

    const handleSubmitbtn=async()=>{
    	if(ValidateInpFields()==true){
	    	try{ 
	    		const form = new FormData();
	    		form.append('title',title);
	    		form.append('tags',tags);
	    		form.append('template_type',template_type);
	    		form.append('subject',subject);
	    		form.append('message',description);    
		        const template_data = await axios.post(APP_URL+'add-lead-template',form,{ headers: {"Authorization" : `Bearer ${token}`}});
		        let template_data_status = JSON.parse(JSON.stringify(template_data.data));
		        if(template_data_status.status==1){		        	
		        	history('/lead-template/template');
		        	toast.success("Template Added SuccessFull");
		        }else{
		        	toast.error("Something went wrong");
		        }		       
	        }catch(e){
	        	toast.error("Something went wrong");
	        	return false;
	        }
    	}else{
    		return false;
    	}
    }

    /*----------load template details-----------*/

    const template_details = async()=>{
    	try{
    		const company_list = await axios.get(APP_URL+'view-lead-template?id='+`${tempid}`,{ headers: {"Authorization" : `Bearer ${token}`}});
	        let companiesList = JSON.parse(JSON.stringify(company_list.data));
	       	if(companiesList.status==1){
	       		await window.tinymce.activeEditor.execCommand('mceInsertContent',false,companiesList.data.lead_templates.message);	
	       		setTemplateDetails({...templateDetails,title:companiesList.data.lead_templates.title,
		      		tags:companiesList.data.lead_templates.tags,
		      		template_type:companiesList.data.lead_templates.template_type,
		      		subject:companiesList.data.lead_templates.subject,
		      		description:companiesList.data.lead_templates.message
		      	});     	

		      }	      
    	}catch(e){
    		return false;
    	}
    }

    /*----------update lead template details---------*/

	const handleUpdateData=async()=>{
    	if(ValidateInpFields()==true){
	    	try{ 
	    		const form = new FormData();
	    		form.append('title',title);
	    		form.append('tags',tags);
	    		form.append('template_type',template_type);
	    		form.append('subject',subject);
	    		form.append('message',description); 
	    		form.append('id',tempid);   
		        const template_data = await axios.post(APP_URL+'update-lead-template',form,{ headers: {"Authorization" : `Bearer ${token}`}});
		        let template_data_status = JSON.parse(JSON.stringify(template_data.data));
		        if(template_data_status.status==1){		        	
		        	history('/lead-template/template');
		        	toast.success("Template Updated SuccessFull");
		        }else{
		        	toast.error("Something went wrong");
		        }		       
	        }catch(e){
	        	toast.error("Something went wrong");
	        	return false;
	        }
    	}else{
    		return false;
    	}
    }


	return(
			<>
			 <div id="app">
			   <div id="main" className="layout-horizontal">
			      <Topbar/>
			      <ToastContainer/>
			      <div className="content-wrapper container">
			         <div className="page-content">
			            <section className="row">
			               <div className="col-12 col-lg-12">
			                  <section className="section">
			                     <div className="row" id="table-hover-row">
			                        <div className="col-12">
			                           <div className="card">
			                              <div className="card-header" style={{borderBottom:'1px solid'}}>
			                              <h5>{tempid!=null?'Edit':'Add'} New Template</h5>
			                           </div>
			                           <div className="card-body">			                           	
			                              <div className="row mt-3">			                              	
			                                 <div className="col-md-6">			                                    
			                                     <div className="row mb-2">
	                                                <div className="col-md-3">
	                                                    <label>Template Title<sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
	                                                </div>
	                                                <div className="col-md-9 form-group">
	                                                    <input type="text" className={"form-control"+(titleError==false?' invalid':'')} name="title" placeholder="Template Title" onChange={(e)=>handleTemplateChange(e)} value={title}/>
	                                                </div> 
                                           		 </div>                                           		 
			                                 </div>
			                                 <div className="col-md-6">			                                    
			                                     <div className="row mb-2">
	                                                <div className="col-md-3">
	                                                    <label>Select Tags <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
	                                                </div>
	                                                <div className="col-md-9 form-group">
	                                                    <select type="text" className={"form-control"+(tagsError==false?' invalid':'')} name="tags" onChange={(e)=>handleTemplateChange(e)}>
	                                                    	<option value="0">Select Tags</option>
	                                                    	{
	                                                    		tagslist && tagslist.map((item)=>(
	                                                    			<>
	                                                    			{
	                                                    				tags==item.id?
	                                                    				<>
	                                                    				<option value={item.id} selected>{item.tags}</option>
	                                                    				</>
	                                                    				:
	                                                    				<>
	                                                    				<option value={item.id}>{item.tags}</option>
	                                                    				</>
	                                                    			}
	                                                    			</>
	                                                    			
	                                                    		))
	                                                    	}
	                                                    </select>
	                                                </div> 
                                           		 </div>                                           		 
			                                 </div>
			                                 <div className="col-md-12">			                                    
			                                     <div className="row mb-2">	                                              
	                                                <label className="mb-2">Select Type<sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>	                                             
	                                                <div className="col-md-12 form-group">
	                                                    <select type="text" className={"form-control"+(typeError==false?' invalid':'')} name="template_type" onChange={(e)=>handleTemplateChange(e)}>
	                                                    	<option value="0">Template Type</option>
	                                                    	{
	                                                    		template_type==1?<>
	                                                    		<option value="1" selected>Mail</option>
		                                                    	<option value="2">WhatsApp</option>
		                                                    	<option value="3">SMS</option>
	                                                    		</>
	                                                    		:template_type==2?<>
	                                                    		<option value="1" >Mail</option>
		                                                    	<option value="2" selected>WhatsApp</option>
		                                                    	<option value="3">SMS</option>
	                                                    		</>
	                                                    		:template_type==3?<>
	                                                    		<option value="1" >Mail</option>
		                                                    	<option value="2" >WhatsApp</option>
		                                                    	<option value="3" selected>SMS</option>
	                                                    		</>
	                                                    		:<>
	                                                    		<option value="1" >Mail</option>
		                                                    	<option value="2" >WhatsApp</option>
		                                                    	<option value="3" >SMS</option>
	                                                    		</>
	                                                    	}
	                                                    	
	                                                    </select>
	                                                </div> 
                                           		 </div>                                           		 
			                                 </div>
			                                 <div className="col-md-12">			                                    
			                                     <div className="row mb-2">	                                               
	                                                <label className="mb-2">Subject<sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>	                                              
	                                                <div className="col-md-12 form-group">
	                                                    <input type="text" className={"form-control"+(subjectError==false?' invalid':'')} placeholder="Subject" name="subject"  onChange={(e)=>handleTemplateChange(e)} value={subject}/>
	                                                </div> 
                                           		 </div>                                           		 
			                                 </div>
			                                 <div className="col-md-12">			                                    
			                                     <div className="row mb-2">	                                                
	                                                <label className="mb-2">Template Description<sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>	                                               
	                                                <div className="col-md-12 form-group">
	                                                <Editor
	                                                className={"textEditor"+(descriptionError==false?' invalid':'')}	                                                 
	                                                 init={{
												          height:350,												          
												          menubar: true,
												          plugins: 'advlist autolink lists link image charmap preview anchor ' +
												            'searchreplace visualblocks code fullscreen ' +
												            'insertdatetime media table code help wordcount'
												          ,
												          toolbar: 'undo redo | formatselect | ' +
												          'bold italic backcolor | alignleft aligncenter ' +
												          'alignright alignjustify | bullist numlist outdent indent | ' +
												          'removeformat | help',
												          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
												        }}
												    onChange={(e)=>handleDescription(e)}
	                                                />	                                                  
	                                                </div> 
                                           		 </div>                                           		 
			                                 </div>
			                                 <div className="col-md-12">			                                    
			                                    {
			                                    	tempid!=null?<>
			                                    	<button className="btn btn-success" onClick={handleUpdateData}>Update</button>
			                                    	</>
			                                    	:<>
			                                    	<button className="btn btn-success" onClick={handleSubmitbtn}>Submit</button>   
			                                    	</>
			                                    }                                     		 
			                                 </div>
			                              </div>
			                              <div className="row mt-3">
			                              	<div className="col-md-12">
			                              		<h5>Available Variable</h5>
			                              		<p>Please enter variable name in relevent places as variable given below :</p>
			                              	</div>
			                              	<div className="col-md-3">
			                              		<ul className="custom_amarjeet">
			                              			<li onClick={(e)=>handleAddVariable(e)}>@customer_name</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@customer_mobile</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@customer_email</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@agent_name</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@agent_mobile</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@agent_email</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@lead_id</li>
			                              			<li onClick={(e)=>handleAddVariable(e)}>@lead_status</li>
			                              		</ul>
			                              	</div>
			                              	<div className="col-md-3">
			                              		<ul className="custom_amarjeet">
			                              			<li onClick={(e)=>handleAddVariable(e)}>@lead_service_tags</li>			                              			
			                              		</ul>
			                              	</div>
			                              </div>
			                           </div>
			                        </div>
			                     </div>
			               </div>
			               </section> 
			         </div>
			         </section>
			      </div>
			      <Footer/>
			   </div>
			</div>
			</div>
			</>
		);
}

export default AddTemplate;