import React,{useState,useEffect} from 'react';
import {APP_URL} from '../../validation/basic';
import axios from 'axios';


const SelectempFrm = ({nextStep,prevStep,user,setUser,edituser,setEdituser})=>{
    const {name,mobile,email,is_password,usepassword,cnfpassword,roll_id,reporting,is_masking,is_allow}=user;
    const [roles,setRoles] =useState([]);
    const [roleerror,setRoleerror] = useState(false);
    const [token,setToken]=useState(localStorage.getItem('token'));

    useEffect(()=>{
        setToken(localStorage.getItem('token'));
        listrole();
    },[])

    const listrole=async()=>{
        try{
             const role_list= await axios.get(APP_URL+'roles',{ headers: {"Authorization" : `Bearer ${token}`}});
            const role_data = JSON.parse(JSON.stringify(role_list.data));
            if(role_data.status==1){
                setRoles(role_data.data.roles);
            }
        }catch(e){
            return false;
        }
    }   


    const selectrole=(e)=>{
        setUser({...user,roll_id:e.target.value});
    }

     const validatefrm = ()=>{
        if(roll_id==0){
            setRoleerror(true);            
        }else{
            setRoleerror(false);
        }        

        if(roll_id==0){
            return false;
        }else{
            return true;
        }
       
   }

   const BtnClick = (e)=>{
        e.preventDefault();           
        if(validatefrm()==true){                      
            nextStep();
        }else{
            return false;            
        }
   }
    return (
<>
        <div className="row pt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-md-3" style={{textAlign:'right'}}>
                                <label>Select Role <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                            </div>
                            <div className="col-md-9 form-group">
                                <select className={"form-control "+(roleerror==true?'invalid':null)} onChange={(e)=>selectrole(e)}>
                                    <option value="0">Select role</option>
                                    {
                                        roles.map((item,index)=>(
                                                <>
                                                {   roll_id==item.id?
                                                    <option value={item.id} selected>{item.role_name}</option>
                                                    :<option value={item.id}>{item.role_name}</option>
                                                }
                                                </>
                                            ))
                                    }
                                </select>
                            </div> 
                        </div>
                    </div>
                    <div className="col-md-2"></div>

              

               <div className="col-12 d-flex justify-content-end">
                 <button className="btn btn-light-secondary me-1 mb-1" onClick={prevStep}>Previous</button>
                <button className="btn btn-primary me-1 mb-1" onClick={BtnClick}>Next</button>
                </div>
              
        </div>
        </>
     
        );
}

export default SelectempFrm;