import React,{useState,useEffect} from 'react';
import { useNavigate  } from "react-router-dom";
import axios from 'axios';
import {APP_URL} from '../validation/basic'
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom_login.css';
const Login = ()=>{
	const [username,setUsername] = useState('');
	const [password,setPassword] = useState('');
	const [errorusername,setErrorusername]=useState(true);
	const [errorpassword,setErrorpassword]=useState(true);
	const [isvalid,setIsvalid]=useState(false);
	const [btndisabled,setBtndisabled] = useState(false);
	let navigate = useNavigate ();
	const handleusername=(e)=>{
		setUsername(e.target.value);
	}

	const handlepassword=(e)=>{
		setPassword(e.target.value);
	}

	const validateFrm = ()=>{

		if(username == '' || username==null){
			setErrorusername(false);
			setIsvalid(false);
		}

		if(password =='' || password==null){
			setErrorpassword(false);
			setIsvalid(false);
		}
		if(username!='' && password !=''){
			return true;
		}

		return false;
			 
	}	

	const BtnClick = async()=>{		
				
		if(validateFrm()==true){
			setBtndisabled(true);
			try{
				const setdata = {
					email:username,
					password:password
				}			
				const response = await axios.post(APP_URL+'login',setdata);
				let js_data = JSON.parse(JSON.stringify(response.data));						
				if(js_data.status==1){				
					localStorage.setItem('isloggedin',js_data.data.user.id);
					localStorage.setItem('Name',js_data.data.user.name);
					localStorage.setItem('token',js_data.data.token);				
					navigate('/dashboard');		
				}else{
					toast.error('something went wrong');		
				}
			}catch(e){
				toast.error('something went wrong'); 
			}
				setBtndisabled(false);		
				
			}
	}
	useEffect(()=>{		
			document.querySelector('body').setAttribute('class','login_custom');		
	},[])	
	return (
		<>
			<div id="auth">        

      <ToastContainer/>
      	 <section id="login-dash">
         <div className="container">
            <div className="login-dash-main">
            <div className="row justify-content-center">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
               <div className="custom_login_text">
                  <h3>Welcome Back to Closecall CRM</h3>
                  <img src="assets/images/login-img-new.png" className="img-fluid mt-3" width="450px" />
                   <p>Manage your interactions with customers and prospects
                   <br/>Track sales opportunities and enhance customer service</p>
               </div>
               </div>
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <div className="custom_login">
                  <div className=" mb-4">
                     <img src="https://closecall.in/corpbiz-call-api/uploads/logo-1683105181192.png" className="img-fluid" width="200px"/>
                     </div>
                        <div className="mb-3">
                        <label>Username</label>
                    <input type="text" className={"form-control form-control-xl "+(errorusername==false?'invalid':'')} placeholder="Username"onChange={handleusername} value={username}/>
                     </div>
                     <div className="mb-3">
                        <label>Password</label>
                    <input type="password" className={"form-control form-control-xl "+(errorpassword==false?'invalid':'')} placeholder="Password" onChange={handlepassword} value={password} />
                     </div>
                     <div className="form-check mb-4">
                       <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                       <label className="form-check-label" for="flexCheckDefault">
                         Keep me logged in
                       </label>
                     </div>
                     <div>

                     	 {
		            	btndisabled==false?
		               	 <button className="btn btn-primary btn-block btn-lg shadow-lg login-dash" onClick={BtnClick}>Log in</button>
		               	 :
		                <button className="btn btn-primary btn-block btn-lg login-dash" type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</button>
                		}            
                       
                     </div>
                    
                  </div>
               </div>
               </div>
            </div>
         </div>
      </section>           
    </div>
    



   		</>
		)
}


export default Login;