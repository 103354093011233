import React,{useState} from 'react';
import Paginationcustom from '../../validation/pagination';
import { CSVLink } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import Loader from '../../loader/loader';
const Call= ({calls,Player,downloadfile,delete_recording,setLoading,loading,permissionlist,accesspermission,pageNo,setPageNo,filterBy,calllogData,exportCallData,exportCallLogsDetails})=>{

	const [currentPage, setCurrentPage] = useState(calllogData!=null?calllogData.page:1);
    // const [recordsPerPage] = useState(calllogData!=null?calllogData.limit:null);
    /*--pagination start here---*/ 

    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = calls;

    const nPages = calllogData!=null?calllogData.total_page_count:null;
    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);
        setPageNo(parseInt(p)); 
        filterBy(parseInt(p))           
    } 

/*--pagination start end here---*/ 



 	/*---- end export csv file ----*/

	return (
		<>
		<div className="col-md-12 mt-3 mb-3">
			<i className="fa fa-volume-control-phone icon-rotation" style={{color:'#0d87c1',fontSize:'18px',marginRight:'10px'}} title="Outgoing Call"></i> <strong style={{marginRight:'10px'}}>Outgoing</strong>
			<i className="fa fa-volume-control-phone" style={{color:'#39c10d',fontSize:'18px',marginRight:'10px'}} title="incoming Call"></i> <strong style={{marginRight:'10px'}}>Incoming</strong>
			<i className="iconly-boldCall-Missed" style={{color:'#ff2020',fontSize:'18px',marginRight:'10px'}} title="Missed"></i> <strong>Missed</strong>
		</div>
		{
                permissionlist && permissionlist.map((p,ind)=>(
			  <>
			  {
                accesspermission.some(item=>p.id==item)==true?
       			<>				                                        	
    			{p.permission=='Export'?<button 				 
				  className="btn btn-success mb-2"			
				  onClick={exportCallLogsDetails}
				> <i className="fa fa-download"></i> Export
		</button> :null }
    			</>
    		
	    		:null  		
	    	}
	    	</>
	    		))
	    	}
			
		 
		 <div className="table-responsive pb-5">
		 <table className="table table-bordered table-hover mb-0">
		                                <thead>
		                                    <tr>
		                                    	<th></th>
		                                    	<th>
		                                    	<div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                    	Employee Name
		                                    	</div>
		                                    	<div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                    	</th>
		                                    	<th>
		                                    	<div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                    	Employee Code
		                                    	</div>
		                                    	<div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                    	</th>		                                       
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Assign No
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>		                                        
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Client No
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Client Name
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Service Name
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Start Time
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        End Time
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>		                                       
		                                        <th>
		                                        <div className="d-flex align-items-center">
		                                    	<div className="me-4">
		                                        Call Duration
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>
		                                        <th>
		                                        <div className="d-flex align-items-center justify-content-between">
		                                    	<div className="me-4">
		                                        Recording
		                                        </div>
		                                        <div>
		                                    	<a className="fa fa-caret-up"></a>
		                                    	<a className="fa fa-caret-down"></a>
		                                    	</div>
		                                    	</div>
		                                        </th>		                                        			                                                                               
		                                        <th>ACTION</th>
		                                    </tr>
		                                </thead>
		                                <tbody>
		                                {
		                                	loading==true?<><tr><td colSpan="10"><Loader/></td></tr></>:
		                                	<>
		                                	{
		                                	currentRecords && currentRecords.map((item,index)=>(

		                                    <tr>
		                                    		<td>
		                                    		{
		                                    			item.status==1?<i className="fa fa-volume-control-phone" style={{color:'#39c10d',fontSize:'24px'}} title="incoming Call"></i>
		                                    			: item.status==2?<i className="fa fa-volume-control-phone icon-rotation" style={{color:'#0d87c1',fontSize:'24px'}} title="Outgoing Call"></i>
		                                    			:<i className="iconly-boldCall-Missed" style={{color:'#ff2020',fontSize:'24px'}} title="Missed"></i>
		                                    		}
		                                    		</td>
		                                     		<td>{item.agent_name}</td>
		                                        <td>{item.agent_code}</td>
		                                        <td>{item.agent_contact_no}</td>		                                        
		                                        <td>{item.client_update_contact_no}</td>
		                                        <td>{item.client_name}</td>
		                                        <td>{item.service_name}</td>
		                                        <td>{item.start_time}</td>
		                                        <td>{item.end_time}</td>		                                       
		                                        <td>{item.call_duration}</td>
		                                        <td className="text-center" >
		                                        {
		                                          permissionlist && permissionlist.map((p,ind)=>(
													  <>
													  {
										                accesspermission.some(item=>p.id==item)==true?
										       			<>				                                        	
										    			{p.permission=='Recording'?item.storage!=null?
		                                        				<ReactAudioPlayer
																  src={item.storage}																  
																  controls																  
																/>
		                                        				:null:null }
										    			</>
										    		
											    		:null  		
											    	}
											    	</>
											    		))
											    	}
		                                        			
		                                        		
		                                        </td>
		                                        <td className="text-center">
		                                        	<div className="buttons">
		                                        	
		                                        	     {
		                                          permissionlist && permissionlist.map((p,ind)=>(
													  <>
													  {
										                accesspermission.some(item=>p.id==item)==true?
										       			<>				                                        	
										    			{p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={(e)=>delete_recording(e)}><i className="fa fa-trash"></i></button>:null}
										    			</>
										    		
											    		:null  		
											    	}
											    	</>
											    		))
											    	}                                  		
		                                        				                                        		
                                        			</div>
		                                        </td>
		                                                                                
		                                    </tr>
		                                    )) 
		                                  

		                                  }
		                                    </>
		                                }
		                                </tbody>
		                            </table>
		                            </div>
			                         <div style={{textAlign:"right",float:"right"}}>
			                            <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
			                        </div>
		</>
		)
}


export default Call;