import React,{useState} from 'react';
import Chart from 'react-apexcharts';

const Analysis = ({summary_detail})=>{	

let total_incoming_call = 0;
let total_outgoing_call=0;
let total_induration = 0;
let total_outduration = 0;
let total_missed_call = 0;
let total_missed_duration=0;
let total_call = 0;
let total_duration = 0;
let total_connected_call = 0;
let total_unique_clients = 0;

if(summary_detail!=null || typeof summary_detail  !='undefined');
{
    let total_length = summary_detail.length;
    for(let i=0;i<total_length;i++){
        total_call+=parseInt(summary_detail[i].total_call);
        total_incoming_call+=parseInt(summary_detail[i].total_incoming);
        total_outgoing_call+=parseInt(summary_detail[i].total_outgoing);
        total_induration+=parseInt(summary_detail[i].incoming_duration);
        total_outduration+=parseInt(summary_detail[i].outgoing_duration);
        total_missed_call+=parseInt(summary_detail[i].total_missed);
        total_duration+=parseInt(summary_detail[i].total_duration);
        total_unique_clients+=parseInt(summary_detail[i].unique_clients);      

    }

    total_connected_call=total_incoming_call+total_outgoing_call;
    
}
  
	return (
		<>
			<div className="row">
				<div className="col-lg-6">
					<Chart 
                        series={[
                            {
                                name:"Total Call",
                                data:[total_call]
                             },
                             {
                                name:"Incoming Call",
                                data:[total_incoming_call]
                             },
                             {
                                name:"Outgoing Call",
                                data:[total_outgoing_call]
                             },

                             {
                                name:"Missed Call",
                                data:[total_missed_call]
                             }

                         ]} 
                    options={{ 
                        labels:['Call Logs'] 
                        }         
                    }           
                    type="bar"
                    width={500} height={320}
                ></Chart> 
				</div>

				<div className="col-lg-6">
					<Chart 
                        series={[
                            {
                                name:"Total call duration",
                                data:[total_duration]
                             },
                             {
                                name:"Incoming duration",
                                data:[total_induration]
                             },
                             {
                                name:"Outgoing duration",
                                data:[total_outduration]
                             }                            

                         ]} 
                    options={{ 
                        labels:['Call Duration'] 
                        }         
                    }           
                    type="bar"
                    width={500} height={320}
                ></Chart> 
				</div>
			</div>
		</>
		)
}

export default Analysis;