import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../layout/footer';
import Topbar from '../../layout/topbar';
import {APP_URL} from '../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
const Users = ()=>{

    const [listusers,setListusers] = useState([]);
    const [emplopyeelist,setEmployeelist]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const [departments,setDepartments]=useState([]);
    const [employee,setEmployee]=useState('');
    const [department,setDepartment]=useState('');
    const [status,setStatus]=useState('');
    const [mobile,setMobile]=useState('');
    const [email,setEmail]=useState('');
    const [selectedemp,setSelectedemp]=useState('');
    const [loading,setLoading]=useState(false);
    const [token,setToken]=useState(localStorage.getItem('token'));
    const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    useEffect(()=>{
        setToken(localStorage.getItem('token'));
        loadusers(); 
        loadDepartment();          

    },[])

     const employeedata=[];
        emplopyeelist.forEach(item=>{
            employeedata.push({value:item.id,label:item.name+'-'+item.user_unique_code});

        })  

    const loadusers = async()=>{
        try{
            setLoading(true);
            const users = await axios.get(APP_URL+'users',{ headers: {"Authorization" : `Bearer ${token}`}});
            let users_list_data = JSON.parse(JSON.stringify(users.data));
            setListusers(users_list_data.data.user_list);
            setEmployeelist(users_list_data.data.user_list); 
            setLoading(false);          
        }catch(e){
            return null;
        }

    }

     const loadDepartment=async()=>{
                const department_details = await axios.get(APP_URL+'departments',{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_department = JSON.parse(JSON.stringify(department_details.data));
                if(js_data_department.status==1){
                    setDepartments(js_data_department.data.departments);
                }

           }  


/*--pagination start here---*/ 

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = listusers.slice(indexOfFirstRecord,indexOfLastRecord);

    const nPages = Math.ceil(listusers.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);            
    } 

/*--pagination start end here---*/ 



    const change_users_status = async(index)=>{
        let id = index;
        if(!window.confirm('Are you sure want to change this')){
            return false;
        }else{
            try{
                const change_status = await axios.get(APP_URL+'users/status/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                let userstatus = JSON.parse(JSON.stringify(change_status.data));
                if(userstatus.status==1){
                    toast.success('status has been changed');
                    loadusers();
                }else{
                    toast.error('soemthing went wrong');
                }
            }catch(e){
                toast.error('something went wrong');
                return false;
            }
        }

    }

    /*-----------------permanent status inactive---------*/

    const change_permanent_users_status = async(index)=>{
        let id = index;
        if(!window.confirm('Are you sure want to change this')){
            return false;
        }else{
            try{
                const change_status = await axios.get(APP_URL+'permanent-inactive-user/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                let userstatus = JSON.parse(JSON.stringify(change_status.data));
                if(userstatus.status==1){
                    toast.success('status has been changed');
                    loadusers();
                }else{
                    toast.error('soemthing went wrong');
                }
            }catch(e){
                toast.error('something went wrong');
                return false;
            }
        }

    }

    /*-----------copy user id-------------*/

    const copyuserid=async(index)=>{
        let id = index;       
        var TempText = document.createElement("input");
        TempText.value = id;
        document.body.appendChild(TempText);
        TempText.select();
        document.execCommand('copy');
        toast.success('copied successfull');
        document.body.removeChild(TempText);
    }


    const delete_users = async(index)=>{
        let id = index;
        if(!window.confirm('Are you sure want to delete this')){
            return false;
        }else{
            try{
                const user_delete = await axios.delete(APP_URL+'users/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                let user_delete_data = JSON.parse(JSON.stringify(user_delete.data));
                if(user_delete_data.status==1){
                    toast.success('user delete successfull');
                    loadusers();
                }else{
                   toast.error('something went wrong'); 
                }

            }catch(e){
                toast.error('something went wrong');
            }   
        }

    } 


    /*----user filter----*/
    const user_filter=async()=>{
        try{
                setLoading(true);
                const user_lists = await axios.get(APP_URL+'user-filter-crm?department='+`${department}`+'&employee='+`${employee}`+'&status='+`${status}`+'&mobile='+`${mobile}`+'&email='+`${email}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                let user_lists_data = JSON.parse(JSON.stringify(user_lists.data));
                if(user_lists_data.status==1){
                     setListusers(user_lists_data.data.user_list);
                     setLoading(false);
                }
        }catch(e){
            return false;
        }
    } 

   

      const headers=[
                {label:"Name",key:"name"},
                {label:"Code",key:"user_unique_code"},
                {label:"Department",key:"department_name"},
                {label:"Mobile",key:"mobile"},
                {label:"Email",key:"email"},
                {label:"Company",key:"company_name"},
                {label:"Designation",key:"designation"},
                {label:"Reporting head",key:"reporting_head"}                              
        ]

     


 listusers.map((item,index)=>{
                listusers[index]['department_name']=item.department.department_name;
                listusers[index]['company_name']=item.company.company_name;
                listusers[index]['designation']=item.role!=null?item.role.role_name:'';
                listusers[index]['reporting_head']=item.reporting;                
            });
const data=listusers;

    const handleemployee=(e)=>{
         setSelectedemp(e); 
        setEmployee(e.value);
    }

    const handledepartment=(e)=>{
        setDepartment(e.target.value);
    }

    const handlestatus=(e)=>{
        setStatus(e.target.value);
    }

    const handlemobile=(e)=>{
        setMobile(e.target.value);
    }

    const handleemail=(e)=>{
        setEmail(e.target.value);
    }



	return (
		<div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
          <ToastContainer/>
  <div className="content-wrapper container">           

<div className="page-content">
    <section className="row">    	
        <div className="col-12 col-lg-12"> 
        <section className="section">
        <div className="row" id="table-hover-row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header" style={{borderBottom:'1px solid'}}>
                    <h5>Employee Details</h5>                   
                    </div>
                    <div className="card-body mt-2">
                        <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Employee</label>
                                         <Select                                     
                                            value={selectedemp}
                                            onChange={(e)=>handleemployee(e)}
                                            options={employeedata}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <select className="form-control" onChange={(e)=>handledepartment(e)}>
                                            <option value="">Select department</option>
                                            {
                                                departments && departments.map((item,index)=>(
                                                    <>
                                                    <option value={item.id}>{item.department_name}</option>
                                                    </>
                                                    ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Status</label>
                                         <select className="form-control" onChange={(e)=>handlestatus(e)}>
                                            <option value="">Select status</option>
                                            <option value="2">Activate</option>
                                            <option value="1">Deactivate</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Mobile</label>
                                         <input type="text" className="form-control" onChange={(e)=>handlemobile(e)}/>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>Email</label>
                                         <input type="text" className="form-control" onChange={(e)=>handleemail(e)}/>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="form-group" style={{marginTop:'21px'}}>
                                       <button className="btn btn-success" onClick={user_filter}>Filter</button>
                                    </div>
                                </div>
                              
                            </div>
                        <div className="buttons">  
                        {
                            permissionlist && permissionlist.map((p,ind)=>(
                                <>
                                {
                                    accesspermission.some(item=>p.id==item)==true?
                            <>                                                 
                           {p.permission=='Add'?<Link to="/master/users/add-user"><button className="btn btn-success rounded-pill"> <i className="iconly-boldPlus"></i> Add User </button></Link>:null}
                           {p.permission=='Export'?<CSVLink
                              data={data}                                                                 
                                      headers={headers}
                                      filename={"user-list.csv"}
                                      className="btn btn-warning rounded-pill"
                                      target="_blank"
                                    >
                              <i className="fa fa-download"></i> Export
                        </CSVLink>:null }
                        </>:null }
                        </>
                        ))
                        } 

                            </div>
                        <div className="table-responsive pb-5">
                            <table className="table table-bordered table-hover mb-0">
                                <thead>
                                    <tr>                                     
                                        <th>User Code</th>
                                        <th>User Info</th>
                                        <th>Status</th>                                        
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    loading==true?<><tr><td colSpan="4"><Loader/></td></tr></>:
                                    <>
                                    {
                                    currentRecords &&
                                    currentRecords.map((item,index)=>(
                                    <tr>

                                        <td>{item.user_unique_code}</td>
                                        <td>
                                            <strong>Name :</strong> {item.name} <br/>
                                            <strong>Company :</strong> {item.company.company_name} <br/>
                                            <strong>Department : </strong>{item.department==null?null:item.department.department_name} <br/>
                                            <strong>Mobile : </strong>{item.mobile}<br/>
                                            <strong>Email : </strong> {item.email}
                                        </td>
                                        <td>{item.employee_status==2?<span className="badge bg-light-success" onClick={(e)=>change_users_status(item.id)}>Activate</span>:<span className="badge bg-light-danger"  onClick={(e)=>change_users_status(item.id)}>Deactivate</span>}</td>
                                        <td>
                                            <div className="buttons">
                                            {
                                                permissionlist && permissionlist.map((p,ind)=>(
                                                 <>
                                                    {
                                                        accesspermission.some(item=>p.id==item)==true?
                                                <>
                                                    {p.permission=='Edit'?<Link to={"/master/user/edit-user/"+(item.id)}><button className="btn btn-success rounded-pill btn-custom" title="edit user"><i className="fa fa-pencil"></i></button></Link>:null}
                                                    {p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={(e)=>delete_users(item.id)} title="delete user"><i className="fa fa-trash"></i></button>:null}
                                                    {p.permission=='Negative'?<button className="btn btn-danger rounded-pill btn-custom" onClick={(e)=>change_permanent_users_status(item.id)} title="permanent deactivate user"><i className="fa fa-thumbs-down"></i></button>:null}
                                                    {p.permission=='Copy'?<button className="btn btn-warning rounded-pill btn-custom" onClick={(e)=>copyuserid(item.encypt_user_id)} title="copy user id"><i className="fa fa-copy"></i></button>:null}
                                                    </>
                                                    :null
                                                }
                                                </>
                                                ))
                                            }
                                                    
                                                    
                                            </div>
                                        </td>                                        
                                    </tr> 
                                    ))
                                }
                                </>
                                }                                 
                                  
                                </tbody>
                            </table>
                        </div>
                        <div style={{textAlign:"right",float:"right"}}>
                            <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
        </div>
        
    </section>
</div>
 <Footer/>
 </div>
          
        </div>
    </div>
		);
}

export default Users;