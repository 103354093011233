import React,{useState,useEffect	} from 'react';
import axios from 'axios';
import {APP_URL} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Loader from '../../loader/loader';
const Uniquecomponent = ({from_date,to_date,calltype,employee,uniqueclients,setUniqueclients,loading,uniqueData,GetUniqueClientListData,uniqueExportData,UniqueReportExport})=>{

	const [currentPage, setCurrentPage] = useState(uniqueData!=null?uniqueData.page:1);
    // const [recordsPerPage] = useState(uniqueData!=null?uniqueData.limit:null);
    /*--pagination start here---*/ 

    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = uniqueclients;

    const nPages = uniqueData!=null?uniqueData.total_page_count:null;
    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);        
        GetUniqueClientListData(parseInt(p))           
    } 

	/*--pagination start end here---*/ 


	return (
		<>
			<div className="row mt-3">
					<div className="col-lg-12">
							<h6>Unique Clients Summary ({uniqueData && uniqueData.total_data})</h6>
					</div>
					<div className="col-lg-12">
					<button
						 
						  className="btn btn-success"
						  onClick={UniqueReportExport}
						>
				  <i className="fa fa-download"></i> Export
				</button> 
							<div className="table-responsive">
							<table className="table table-bordered">
									<thead>
											<tr>
													<th style={{width:"200px"}}>Agent name</th>
													<th>Agent code</th>
													<th>Client No</th>
													<th>Total Calls</th>
													<th>Total Duration(in seconds)</th>
													<th>Incoming Call</th>
													<th>Incoming Duration(in seconds)</th>
													<th>Outgoing Call</th>
													<th>Outgoing Duration(in seconds)</th>
													<th>Missed</th>
													<th>Last Call Details</th>
													
											</tr>
									</thead>
									<tbody>
									{
										loading==true?<><tr><td colSpan="11"><Loader/></td></tr></>:
						              	<>
						              	{
										currentRecords && currentRecords.map((item,index)=>(
											<tr>
													<td>{item.agent_name}</td>
													<td>{item.agent_code}</td>
													<td>{item.client_update_contact_no}</td>
													<td>{item.total_call}</td>
													<td>{item.total_call_duration}</td>
													<td>{item.total_incoming_call}</td>
													<td>{item.total_incoming_call_duration}</td>
													<td>{item.total_outgoing_call}</td>
													<td>{item.total_outgoing_call_duration}</td>
													<td>{item.total_missed_call}</td>
													<td>0</td>
											</tr>
											))
										}
										</>
									}
									</tbody>
							</table>
							</div>

							 <div style={{textAlign:"right",float:"right"}}>
									 <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
	                        </div>
					</div>
			</div>
		</>
		)
}

export default Uniquecomponent;