import React from 'react';
import Summary from './summary';
import Analysis from './analysis';
import Nevercomponent from './never_component';
import Notpickupcomponent from './not_pickup';
import Uniquecomponent from './unique_component';
import Callcomponent from './call_history_component';

const Finalcomponent=({data,periodic,summary_detail,from_date,to_date,employee,calltype,neverattended,setNeverattended,uniqueclients,setUniqueclients,calllog,setCalllog,loading,summaryData,neverData,uniqueData,callData,filterBy,NeverAttendedListData,GetUniqueClientListData,GetCallLogHistoryData,periodicExportData,neverExportData,uniqueExportData,callExportData,exportPeriodicReportDetails,neverAttendedReportExport,UniqueReportExport,callLogExportHistory})=>{

	if(data==1){
		return (
			<>
				<Summary periodic={periodic!=null?periodic:null} summary_details={summary_detail!=null?summary_detail:null} from_date={from_date} to_date={to_date} loading={loading} summaryData={summaryData} filterBy={filterBy} periodicExportData={periodicExportData} exportPeriodicReportDetails={exportPeriodicReportDetails}/>
			</>
			)
	}else if(data==2){
		return(
			<>
				<Analysis summary_detail={summary_detail!=null?summary_detail:null} />
			</>
		)
	}else if(data==3){
		return (
		<>
			<Nevercomponent from_date={from_date} to_date={to_date} calltype={calltype} employee={employee} neverattended={neverattended} setNeverattended={setNeverattended} loading={loading} neverData={neverData} NeverAttendedListData={NeverAttendedListData} neverExportData={neverExportData} neverAttendedReportExport={neverAttendedReportExport}/>
		</>
		)
	}else if(data==5){
		return (
		<>
			<Uniquecomponent from_date={from_date} to_date={to_date} calltype={calltype} employee={employee} uniqueclients={uniqueclients} setUniqueclients={setUniqueclients} loading={loading} uniqueData={uniqueData} GetUniqueClientListData={GetUniqueClientListData} uniqueExportData={uniqueExportData} UniqueReportExport={UniqueReportExport}/>
		</>
		)
	}else if(data==6){
		return (
		<>
			<Callcomponent from_date={from_date} to_date={to_date} calltype={calltype} employee={employee} calllog={calllog} setCalllog={setCalllog} loading={loading} callData={callData} GetCallLogHistoryData={GetCallLogHistoryData} callExportData={callExportData} callLogExportHistory={callLogExportHistory}/>
		</>
		)
	}

}

export default Finalcomponent;