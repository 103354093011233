import React,{useState  ,useEffect}  from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import axios from 'axios';
import {APP_URL,getdates} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';

const AddLead= (props)=>{
    const [tags,setTags]=useState('');
    const [inphidden,setInphidden]=useState('');
    const [isedit,setIsedit]=useState(false);
    const [errortags,setErrortags]=useState(true);
    const [companies,setCompanies] = useState([]);
    const [isvalid,setIsvalid]=useState(false);
    const [btndisabled,setBtndisabled] = useState(false);
    const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');    
    const [nameerror,setNameerror]=useState(true);
    const [emailerror,setEmailerror]=useState(true);
    const [mobileerror,setMobileerror]=useState(true);
    const [tagserror,setTagserror]=useState(true);    
    const [token,setToken]=useState(localStorage.getItem('token'));
    const [leadId,setLeadId]=useState(props.leadID!=null?props.leadID:null);
    const [leadData,setLeadData]=useState({
        name:'',
        cntry_code:'',
        mobile:'',
        email:'',
        service_tags:0
    });
    const {name,cntry_code,mobile,email,service_tags}=leadData;
    useEffect(()=>{
        setToken(localStorage.getItem('token')); 
        loadtags(); 
        if(leadId!=null){
            viewLeadDetails(leadId);
        }               
    },[props.addLeadStatus]);
    const handlechange = (e)=>{
        setLeadData({...leadData,[e.target.name]:e.target.value});
    }
    const Validatetags = ()=>{
        if(name===''){
            setNameerror(false);
        }else{
            setNameerror(true);
        } 
        if(mobile===''){
            setMobileerror(false);
        }else{
            setMobileerror(true);
        }
        if(email==='' || emailValidate(email)===false){
            setEmailerror(false);
        }else{
            setEmailerror(true);
        }

        if(service_tags===0){
            setTagserror(false);
        }else{
            setTagserror(true);
        }        

        if(name==='' || mobile==='' || email==='' || service_tags===0 || emailValidate(email)===false){
            return false;
        }else{
            return true;
        }
    }

    /*----------email validate--------------*/

    const emailValidate=(email)=>{
        let regex = new RegExp(/\S+@\S+\.\S+/);
        if(regex.test(email)){
            return true;
        }else{
            return false;
        }
    }

     const loadtags = async()=>{      
        const company_list = await axios.get(APP_URL+'list-active-lead-tags',{ headers: {"Authorization" : `Bearer ${token}`}});
        let companiesList = JSON.parse(JSON.stringify(company_list.data));
        setCompanies(companiesList.data.lead_tags);
             
    }

    const LeadSubmit = async()=>{
        if(Validatetags()==true){
            try{ 
                props.setAddcheckStatus(false);              
                const lead_create = await axios.post(APP_URL+'create-new-lead',leadData,{ headers: {"Authorization" : `Bearer ${token}`}});            
                var js_data_company_create = JSON.parse(JSON.stringify(lead_create.data));           
                if(js_data_company_create.status==1){                                                    
                    toast.success('Leads created successfull');
                    props.setAddcheckStatus(true); 
                    props.setAddLeadStatus(false);                        
                }else{
                    toast.error('something went wrong');
                }
            }catch(e){
                toast.error('something went wrong');
                return false
            }
        }else{
            return false;
        }
    }    

   /*-------back lead inbox-----*/

   const back_lead_inbox=()=>{
        props.setAddLeadStatus(false);
   }

   /*---------view lead details---------*/

const viewLeadDetails=async(lead_id)=>{
   try{
      const users = await axios.get(APP_URL+'view-lead-details?id='+`${lead_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      if(users_list_data.status==1){
        setLeadData({...leadData,name:users_list_data.data.lead_list.name,
                        email:users_list_data.data.lead_list.email_id,
                        mobile:users_list_data.data.lead_list.mobile,
                        service_tags:users_list_data.data.lead_list.tags
                    });
        console.log(users_list_data.data.lead_list)
      }  
        
   }catch(e){
      return null;
   }
}

/*-------------update lead details------------*/
 const updateLeadDetails = async()=>{
        if(Validatetags()==true){
            try{ 
                props.setAddcheckStatus(false); 
                let updated_lead_data = Object.assign(leadData,{id:leadId});             
                const lead_create = await axios.post(APP_URL+'update-lead-details',updated_lead_data,{ headers: {"Authorization" : `Bearer ${token}`}});            
                var js_data_company_create = JSON.parse(JSON.stringify(lead_create.data));           
                if(js_data_company_create.status==1){                                                    
                    toast.success('Leads updated successfull');
                    props.setLeadID(null); 
                    props.setAddcheckStatus(true); 
                    props.setAddLeadStatus(false);                 
                       
                }else{
                    toast.error('something went wrong');
                }
            }catch(e){
                toast.error('something went wrong');
                return false
            }
        }else{
            return false;
        }
    } 

  
	return (
                <div className="content-wrapper container">
                <div className="page-content">
                <section className="row">
                <div className="col-12 col-lg-12">
                   <section id="basic-vertical-layouts">
                      <div className="row match-height">
                         <div className="col-md-12 col-12">
                            <div className="card">                            
                              <div className="card-header" style={{borderBottom:'1px solid'}}>
                              <h5><i className="fa fa-angle-left me-2" onClick={back_lead_inbox}></i>Add New Lead</h5>
                                </div>
                               <div className="card-content">
                                  <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row mb-2">
                                                <div className="col-md-3">
                                                    <label>Name <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                                </div>
                                                <div className="col-md-9 form-group">
                                                    <input type="text" id="name" className={"form-control "+(nameerror==false?'invalid':'')} name="name" placeholder="Name" onChange={(e)=>handlechange(e)} value={name}  />
                                                </div>                              
                                               
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-3">
                                                    <label>Mobile <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                                </div>
                                                <div className="col-md-9 form-group">
                                                    <input type="text" id="name" className={"form-control "+(mobileerror==false?'invalid':'')} name="mobile" placeholder="Mobile" onChange={(e)=>handlechange(e)} value={mobile}  />
                                                </div>                              
                                               
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-2">
                                                <div className="col-md-3">
                                                    <label>Email <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                                </div>
                                                <div className="col-md-9 form-group">
                                                    <input type="text" id="name" className={"form-control "+(emailerror==false?'invalid':'')} name="email" placeholder="Email" onChange={(e)=>handlechange(e)} value={email}  />
                                                </div>                              
                                               
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-md-3">
                                                    <label>Service Tags <sup style={{color:"red"}}><i className="fa fa-asterisk" style={{fontSize:'8px'}}></i></sup></label>
                                                </div>
                                                <div className="col-md-9 form-group">
                                                    <select className={"form-control "+(tagserror==false?'invalid':'')} name="service_tags"onChange={(e)=>handlechange(e)}>
                                                        <option value="0">select service tags</option>
                                                        {
                                                            companies && companies.map((item)=>(
                                                                <>                                                                    
                                                                    <option value={item.id} selected={item.id==service_tags?true:false}>{item.tags}</option>
                                                                </>
                                                            ))
                                                        }
                                                    </select>
                                                </div>                              
                                               
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                        {
                                            props.isEditLead!=true?
                                            <>
                                            <button className="btn btn-success" onClick={LeadSubmit}>Submit</button>
                                            </>
                                            :
                                            <>
                                                 <button className="btn btn-success" onClick={updateLeadDetails}>Update</button>
                                            </>
                                        }
                                        </div>
                                    </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </section>
                </div> 
                </section>
                </div>
                </div> 
    
	);
	
}

export default AddLead;