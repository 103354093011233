import React,{useState,useEffect} from 'react';
import {APP_URL} from '../../validation/basic';
import axios from 'axios';

const Setperimissionfrm = ({prevStep,nextStep,user,setUser})=>{
	const [menupermission,setMenupermission]=useState([]);
	 const {name,mobile,email,is_password,usepassword,cnfpassword,employee_type,roll_id,menu,permission,is_masking,is_allow}=user;
	const [token,setToken]=useState(localStorage.getItem('token'));
	 useEffect(()=>{
	 	setToken(localStorage.getItem('token'));
	 	loadpermission();
	 },[]);

	 const loadpermission=async()=>{
	 	try{
	 		const menu_permission =  await axios.get(APP_URL+'permissions',{ headers: {"Authorization" : `Bearer ${token}`}});
	 		let menus = JSON.parse(JSON.stringify(menu_permission.data));
	 		if(menus.status==1){
	 			setMenupermission(menus.data.permissions);
	 		}

	 	}catch(e){
	 		return null
	 	}
	 }

	 const checkmenubtn = (e)=>{
	 	let menus = menu;
	 	menus.push(e.target.value);
	 	setUser({...user,menu:menus});
	 }

	 const checkpermissionBtn = (e)=>{	 	
	 	let permissions=permission;
	 	permissions.push(e.target.value);
	 	setUser({...user,permission:permissions});	 	
	 }
	 
	return(
		<>
			<div className="row pt-3">
				<div className="col-lg-12">
					<table className="table table-bordered">
						<thead>
						<tr className="setpermission-head-row">
							<th>Features</th> 
							<th colspan="2">Can Manage</th> 							
						</tr>
						</thead>
						<tbody>
							

						{

							menupermission.map((item,index)=>(
							<tr>
								<td><strong><input type="checkbox" value={item.id} onClick={(e)=>checkmenubtn(e)}/> {item.menu_name}</strong></td>
								<td >
								<div className="row">
								{
									item.permission!=null?
										item.permission.map((pr,prm)=>(
										<div className="col-md-3">
										<div className="form-check form-switch">
				                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value={pr.id} onClick={(e)=>checkpermissionBtn(e)}/>
				                            <label className="form-check-label" for="flexSwitchCheckDefault">{pr.permission}</label>
	                        			</div>
										</div>
									))
								:null
							}
							</div>
							</td>
								
							</tr>
							))
						}

							


						</tbody>
					</table>
				</div>
				  <div className="col-12 d-flex justify-content-end">
                 <button className="btn btn-light-secondary me-1 mb-1" onClick={prevStep}>Previous</button>
                <button className="btn btn-primary me-1 mb-1" onClick={nextStep}>Next</button>
                </div>
			</div>
		</>
		);

}

export default Setperimissionfrm;