import { configureStore } from '@reduxjs/toolkit';
import {persistStore,persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from './userslice';
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, userReducer)
const store= configureStore({
  reducer: {
    user: persistedReducer   
  },
})

export const persistor = persistStore(store);
export default store;