import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../layout/footer';
import Master from '../layout/master';
import Topbar from '../layout/topbar';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL,getdates} from '../validation/basic';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Paginationcustom from '../validation/pagination';
import Select from 'react-select';

const OutBoundDashboard = ()=>{
    const [fromdate,setFromdate]=useState(getdates(0));
    const [todate,setTodate]=useState(getdates(0));
    const [employees,setEmployees]=useState('');
    const [selectedemp,setSelectedemp]=useState('');
    const [employee,setEmployee]=useState([]);
    const [departments,setDepartments]=useState([]);
    const [selectedDept,setSelectedDept]=useState('');
    const [Depts,setDepts]=useState('');  
    
    const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
  
    const [token,setToken]=useState(localStorage.getItem('token')); 

    const [outBound,setOutBound]=useState({
        outbound_call:0,
        sales:0,
        avg_call_duration:0,
        avg_call_pft:0
    });

    const {outbound_call,sales,avg_call_duration,avg_call_pft}=outBound;

  const [dailychart,setDailychart]=useState({
        series_chart:[],
        date_chart:[]
  })  
  const {series_chart,date_chart}=dailychart;  

    useEffect(()=>{ 
        setToken(localStorage.getItem('token'));
        loadusers(Depts);
        loadDepartment();
        filterOutbound();
       
       
    },[]);
    const data=[];
    employee.forEach(item=>{
        data.push({value:item.id,label:item.name+'-'+item.user_unique_code});

    }) 

    const departmentData=[];
    departments.forEach(item=>{
        departmentData.push({value:item.id,label:item.department_name});
    })   
   

    
  
     const loadusers = async(depts)=>{
        try{
            const users = await axios.get(APP_URL+'user-filter-crm?department='+`${depts}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            let users_list_data = JSON.parse(JSON.stringify(users.data));
            setEmployee(users_list_data.data.user_list);
        }catch(e){
            return null;
        }
    }
   /*----------load department------------*/

     const loadDepartment=async()=>{
                const department_details = await axios.get(APP_URL+'departments',{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_department = JSON.parse(JSON.stringify(department_details.data));
                if(js_data_department.status==1){
                    setDepartments(js_data_department.data.departments);
                }

           }

   const handleemployee = (e)=>{ 
            setSelectedemp(e);     
            setEmployees(e.value);                        
    }

     const handledepartment = (e)=>{ 
            setSelectedDept(e);     
            setDepts(e.value); 
            loadusers(e.value);                       
    }

     const handlefromdate=(e)=>{
        setFromdate(e.target.value);
    }
    const handletodate=(e)=>{
        setTodate(e.target.value);
    }

    const filterOutbound=async()=>{
        try{
            const outbound_details = await axios.get(APP_URL+'outbound-dashboard-report?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&dept='+`${Depts}`+'&user_id='+`${employees}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            var js_data_outbound = JSON.parse(JSON.stringify(outbound_details.data));
            if(js_data_outbound.status==1){
                setOutBound({...outBound,
                    outbound_call:js_data_outbound.data.outbound.total_outgoing_call,
                    sales:0,
                    avg_call_duration:js_data_outbound.data.outbound.avg_call_duration,
                    avg_call_pft:0

                });

            }
              ChartOutbound();
        }catch(e){
            return null
        }
    }

     const ChartOutbound=async()=>{
        try{
            const outbound_daily = await axios.get(APP_URL+'daily-outbound-chart?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&dept='+`${Depts}`+'&user_id='+`${employees}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            var js_data_outbound_daily = JSON.parse(JSON.stringify(outbound_daily.data));
            if(js_data_outbound_daily.status==1){
                setDailychart({...dailychart,
                    series_chart:js_data_outbound_daily.data.ChartDailyBasis.series,
                    date_chart:js_data_outbound_daily.data.ChartDailyBasis.date

                });
            }
        }catch(e){
            return null
        }
    } 

 
	return (
        
	<div id="app">      
        <div id="main" className="layout-horizontal">
           <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
           <ToastContainer />
  <div className="content-wrapper container">         
<div className="page-heading">
<div className="row">
    <div className="col-lg-3">
        <h3>Outbound</h3>
    </div>
    <div className="col-lg-9">

    <table className="table table-borderless">
                   
                    <tbody>
                        <tr>
                            <td style={{width:'250px'}}>

                                  <Select                                     
                                    value={selectedDept}
                                    onChange={(e)=>handledepartment(e)}
                                    options={departmentData}
                                  />
                               
                            </td>
                            <td style={{width:'250px'}}>

                                  <Select                                     
                                    value={selectedemp}
                                    onChange={(e)=>handleemployee(e)}
                                    options={data}
                                  />
                               
                            </td>
                            <td>
                                <input type="date" className="form-control" value={fromdate} onChange={(e)=>handlefromdate(e)}/>
                            </td>
                            <td>
                                 <input type="date" className="form-control" value={todate} onChange={(e)=>handletodate(e)} />
                            </td>
                            <td>
                                <button className="btn btn-warning" onClick={filterOutbound}>Filter</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div></div>
</div>
<div className="page-content">
    <div className="row">
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">                       
                        <div className="col-lg-12">
                            <div className="row">
                                 <div className="col-3 col-lg-3 col-md-3">
                                    <div className="card">
                                        <div className="card-body" style={{background:'#ffbf4f',padding:'10px',borderRadius:'5px'}}>
                                            <div className="row">                                               
                                                <div className="col-md-12">
                                                    <h6 className="font-semibold">Outbound Calls</h6>
                                                    <h6 className="font-extrabold mb-0">{outbound_call}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 <div className="col-3 col-lg-3 col-md-3">
                                    <div className="card">
                                        <div className="card-body" style={{background:'#ed4fff61',padding:'10px',borderRadius:'5px'}}>
                                            <div className="row">                                               
                                                <div className="col-md-12">
                                                    <h6 className="font-semibold">Sales</h6>
                                                    <h6 className="font-extrabold mb-0">{sales}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3 col-lg-3 col-md-3">
                                    <div className="card">
                                        <div className="card-body" style={{background:'#fffc558c',padding:'10px',borderRadius:'5px'}}>
                                            <div className="row">                                                
                                                <div className="col-md-12">
                                                    <h6 className="font-semibold">Avg Call Duration</h6>
                                                    <h6 className="font-extrabold mb-0">{avg_call_duration}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 <div className="col-3 col-lg-3 col-md-3">
                                    <div className="card">
                                        <div className="card-body" style={{background:'#4ffffa42',padding:'10px',borderRadius:'5px'}}>
                                            <div className="row">                                                
                                                <div className="col-md-12">
                                                    <h6 className="font-semibold">Avg Call PFT</h6>
                                                    <h6 className="font-extrabold mb-0">{avg_call_duration}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   

                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header text-center">
                                            <h5><u>Daily Outbound Call Vs Sales</u></h5>
                                        </div>
                                            <div className="card-body text-center">
                                                   <Chart 
                                                    series={series_chart}
                                                    options={{
                                                         chart: {
                                                            type: 'bar',
                                                            height: 350
                                                          },
                                                          plotOptions: {
                                                            bar: {
                                                              horizontal: false,
                                                              columnWidth: '55%',
                                                              endingShape: 'rounded'
                                                            },
                                                          },
                                                          dataLabels: {
                                                            enabled: false
                                                          },
                                                          stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                          },
                                                         
                                                          xaxis: {
                                                            categories: date_chart,
                                                          },
                                                          yaxis: {
                                                            title: {
                                                              text: 'Outbound of Calls'
                                                            }
                                                          },
                                                          fill: {
                                                            opacity: 1,                
                                                          },
                                                          colors:['#3255c5','#059929'],
                                                          tooltip: {
                                                            y: {
                                                              formatter: function (val) {
                                                                return  val
                                                              }
                                                            }
                                                          }
                                                    }}
                                                     type="bar"
                                                width="100%" height={320}
                                            ></Chart>       
                                           
                                            </div>
                                        </div>
                                </div>
                            </div>

                            
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
   
        
   
</div>
<Footer/>
 </div>
           
        </div>
    </div>
   
		);
}


export default OutBoundDashboard;