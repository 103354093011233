import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../../layout/topbar';
import Footer from '../../../layout/footer';
import {APP_URL,token} from '../../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginationcustom from '../../../validation/pagination';
const Menu=()=>{
	const [menus,setMenus] = useState([]);
	const [permissionlist,setPermissionlist]=useState([]);
	const [accesspermission,setAccesspermission]=useState([]);
	const [menuids,setMenuids]=useState('');

	const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
	const [token,setToken]=useState(localStorage.getItem('token'));
	useEffect(()=>{
		setToken(localStorage.getItem('token'));
		getmenus();
	},[])

	/*--pagination start here---*/ 

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = menus.slice(indexOfFirstRecord,indexOfLastRecord);

    const nPages = Math.ceil(menus.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);            
    } 

	/*--pagination start end here---*/

	const getmenus = async()=>{
		try{
				const menusdata = await axios.get(APP_URL+'menus',{ headers: {"Authorization" : `Bearer ${token}`}});
				let menuslist = JSON.parse(JSON.stringify(menusdata.data));
				if(menuslist.status==1){
					setMenus(menuslist.data.menus);
				}

		}catch(e){
			return false;
		}
	}

	const menus_Delete=async(index)=>{
		if(!window.confirm("Are you sure want to delete this")){
			return false
		}else{
			try{

				const menusdata_delete = await axios.delete(APP_URL+'menus/'+`${index}`,{ headers: {"Authorization" : `Bearer ${token}`}});
				let menuslist = JSON.parse(JSON.stringify(menusdata_delete.data));
				if(menuslist.status==1){
					toast.success('Menu deleted successfull');
					getmenus();
				}else{
					toast.error('something went wrong');
				}

			}catch(e){
				return false;
			}
		}
	}


	const menus_status_change=async(index)=>{
		if(!window.confirm("Are you sure want to change this")){
			return false
		}else{
			try{

				const menusdata_delete = await axios.get(APP_URL+'menus/status/'+`${index}`,{ headers: {"Authorization" : `Bearer ${token}`}});
				let menuslist = JSON.parse(JSON.stringify(menusdata_delete.data));
				if(menuslist.status==1){
					toast.success('status change successfull');
					getmenus();
				}else{
					toast.error('something went wrong');
				}

			}catch(e){
				return false;
			}
		}
	}

	return (
		<>
			<div id="app">
		        <div id="main" className="layout-horizontal">
		          	 <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>

		          <ToastContainer/>
					  <div className="content-wrapper container">           
					
					<div className="page-content">
					    <section className="row">    	
				        <div className="col-12 col-lg-12"> 
				        <section className="section">
				        <div className="row" id="table-hover-row">
				            <div className="col-12">
				                <div className="card">
				                    <div className="card-header" style={{borderBottom:'1px solid'}}>
				                    <h5>Manage Menu</h5>
				                    <div className="buttons mt-4">
				                    {
                                    		 permissionlist && permissionlist.map((p,ind)=>(
         									  <>
         									  {
			                                    accesspermission.some(item=>p.id==item)==true?
			                           			<>				                                        	
                                    				{p.permission=='Add'?<Link to="/master/manage/add-menu"><button className="btn btn-warning rounded-pill"><i class="fa fa-plus"></i>Add New Menu</button></Link>:null}
                                    		
                                    		</>
                                    		:null
                                    		
                                    	}
                                    	</>
                                    		))
                                    	}
				                       							                       			
				                       	</div>		                   
				                    </div>
				                    <div className="card-body mt-4">                     
				                       	
				                        <div className="table-responsive pb-5">
				                            <table className="table table-bordered table-hover mb-0">
				                                <thead>
				                                    <tr>				                                    	
				                                    	<th>Menu</th>
				                                    	<th>Parent Menu</th>
				                                    	<th>Order</th>                                   			                                       
				                                        <th>Status</th>			                                                                               
				                                        <th>ACTION</th>
				                                    </tr>
				                                </thead>
				                                <tbody>
				                                {
				                                	currentRecords && currentRecords.map((item,index)=>(

				                                    <tr>		                                    				                                        
				                                       	<td>{item.menu_name}</td>
				                                       	<td>{item.parent_menu}</td>
				                                       	<td>{item.order}</td>				                                       
				                                         <td>{item.status==1?<span className="badge bg-light-success" >Activate</span>:<span className="badge bg-light-danger" >Deactivate</span>}</td>
				                                        <td>
				                                        	<div className="buttons">
				                                        	{
				                                    		 permissionlist && permissionlist.map((p,ind)=>(
				         									  <>
				         									  {
							                                    accesspermission.some(item=>p.id==item)==true?
							                           			<>
					                                        		{p.permission=='Activate'?<button className="btn btn-warning rounded-pill btn-custom" title="block number" onClick={()=>menus_status_change(item.id)}><i className="fa fa-ban"></i></button>:null}
					                                        		{p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" title="delete number" onClick={()=>menus_Delete(item.id)} ><i className="fa fa-trash"></i></button>:null}				                                        		
				                                        		</>
					                                    		:null
					                                    		
					                                    	}
					                                    	</>
					                                    		))
					                                    	}
				                       		
		                                        			</div>
				                                        </td>
				                                                                                
				                                    </tr> 
				                                    ))
				                                	
				                                  }                                 
				                                  
				                                </tbody>
				                            </table>
				                        </div>
				                         <div style={{textAlign:"right",float:"right"}}>
				                            <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
				                        </div>
				                    </div>
				                </div>
				            </div>
				        </div>
				    </section> 
				        </div>
				        
				    </section>
				</div>
				  <Footer/>
				 </div>
				         
				        </div>
		    </div>
		</>
		)
}


export default Menu;