import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {APP_URL} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Loader from '../../loader/loader';
const Callcomponent = ({from_date,to_date,calltype,employee,calllog,setCalllog,loading,callData,GetCallLogHistoryData,callExportData,callLogExportHistory})=>{

	const [currentPage, setCurrentPage] = useState(callData!=null?callData.page:1);
    // const [recordsPerPage] = useState(callData!=null?callData.limit:null);
    /*--pagination start here---*/ 

    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = calllog;

    const nPages = callData!=null?callData.total_page_count:null;
    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);        
        GetCallLogHistoryData(parseInt(p))           
    } 


	
	return (
		<>
			<div className="row mt-3">
					<div className="col-lg-12">
							<h6>Call History ({callData && callData.total_data})</h6>
					</div>
					<div className="col-lg-12">
					<button						  
						className="btn btn-success"
						onClick={callLogExportHistory}						
						>
				  <i className="fa fa-download"></i> Export
				</button> 
							<table className="table table-bordered">
									<thead>
											<tr>
													<th>Agent name</th>
													<th>Agent code</th>
													<th>Assign No</th>
													<th>Client No</th>
													<th>Client Name</th>
													<th>Service Name</th>
													<th>Call type</th>
													<th>Start Time</th>
													<th>End Time</th>
													<th>Call Duration</th>
													
											</tr>
									</thead>
									<tbody>
										{
											loading==true?<><tr><td colSpan="8"><Loader/></td></tr></>:
							              	<>
							              	{
											currentRecords && currentRecords.map((item,index)=>(
											<tr>
													<td>{item.agent_name}</td>
													<td>{item.agent_code}</td>
													<td>{item.agent_contact_no}</td>
													<td>{item.client_update_contact_no}</td>
													<td>{item.client_name}</td>
													<td>{item.service_name}</td>
													<td>{item.status==1?'incoming':item.status==2?'outgoing':item.status==3?'missed':null}</td>
													<td>{item.start_time}</td>
													<td>{item.end_time}</td>
													<td>{item.call_duration}</td>
													
											</tr>
											))
										}
										</>
									}
									</tbody>
							</table>
							 <div style={{textAlign:"right",float:"right"}}>
									 <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
	                        </div>
					</div>
			</div>
		</>
		)
}

export default Callcomponent;