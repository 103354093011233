import ReactDOM from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';

const Notpickup=()=>{
	return(
			<>
				<div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar/>
			  <div className="content-wrapper container">           
			
			<div className="page-content">
			    <section className="row">    	
		        <div className="col-12 col-lg-12"> 
		        <section className="section">
		        <div className="row" id="table-hover-row">
		            <div className="col-12">
		                <div className="card">
		                    <div className="card-header" style={{borderBottom:'1px solid'}}>
		                    		<h5>Not Pickup By Clients</h5>		                   
		                    </div>
		                    <div className="card-body">                     
		                       

		                    	<div className="row" >
		                    			
		                    				<div className="col-md-12">
		                    					<div className="table-responsive">
		                    							<table className="table table table-borderless mb-0">
		                    								<thead>
				                    								<tr>
				                    										<th>From date</th>
				                    										<th>To date</th>
				                    										<th>Select tags</th>
				                    										<th>Select Employee</th>				                    										
				                    										<th></th>
				                    								</tr>
		                    								</thead>
		                    								<tbody>
		                    											<tr>
		                    													<td>
		                    																<input type="date" className="form-control" />		                    																		
		                    													</td>
		                    													<td>
		                    																	<input type="date" className="form-control" />
		                    													</td>
		                    													<td>
		                    																<select className="form-control">
		                    																		<option value="0">Select tags</option>
		                    																</select>
		                    													</td>
		                    													<td>
		                    																<select className="form-control">
		                    																		<option value="0">Select employee</option>
		                    																</select>
		                    													</td>
		                    												
		                    													
		                    													<td>		                    															
		                    																<button className="btn btn-outline-success">Apply</button>		                    															
		                    													</td>
		                    											</tr>
		                    								</tbody>
		                    							</table>
		                    							</div>
		                    				</div>

		                    	</div>
		                    		<hr/>
		                    		<div className="row">
		                    				<div className="col-md-12">
		                    						<div className="col-sm-12">
		                    								<h6>Reports</h6>
		                    						</div>	

		                    						                    				 
		                    							 <div className="table-responsive pb-5">
		                            <table className="table table-bordered table-hover mb-0">
		                                <thead>
		                                    <tr>
		                                    	<th>Month</th>		                                       
		                                        <th>Used Space</th>
		                                        <th>No of files</th>		                                                                               
		                                        <th>ACTION</th>
		                                    </tr>
		                                </thead>
		                                <tbody>
		                                    <tr>
		                                        <td>test</td>
		                                        <td>12MB</td>
		                                        <td>2</td>
		                                        <td>
		                                        	<div className="buttons">
		                                        		<button class="btn btn-success rounded-pill"><i className="fa fa-pencil"></i></button>
		                                        		<button class="btn btn-danger rounded-pill"><i className="fa fa-trash"></i></button>
		                                        		<button class="btn btn-secondary rounded-pill"><i className="fa fa-eye"></i></button>
                                        			</div>
		                                        </td>
		                                                                                
		                                    </tr>                                  
		                                  
		                                </tbody>
		                            </table>
		                        </div>
		                    				</div>
		                    		</div>
		                       
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section> 
		        </div>
		        
		    </section>
		</div>
		 <Footer/>
		 </div>
		          
		        </div>
    </div>
			</>
		);
}

export default Notpickup;