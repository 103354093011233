import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useNavigate  } from "react-router-dom";
import {APP_URL,BASE_URL} from '../validation/basic'
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Topbar = ({menuids,setMenuids,setAccesspermission,accesspermission,permissionlist,setPermissionlist})=>{
    const [menus,setMenus]=useState([]);
    const [reNewRoute,setReNewRoute]=useState('');
    const [token,setToken]=useState(localStorage.getItem('token'));
    let navigate = useNavigate();
    const username = localStorage.getItem('Name');
    const checklogin =()=>{ 
       const isloggedin=localStorage.getItem('isloggedin');
       const token = localStorage.getItem('token');
       if(isloggedin==null||token==null||isloggedin==''||token==''){
           localStorage.clear();
           window.location.href="/"; 
       }
    }
    useEffect(()=>{
        setToken(localStorage.getItem('token'));         
        //checklogin();               
        loaddynamicMenu(); 
        loadmenuusingpath();
    },[]) 

      

    const logout = async(e)=>{
        e.preventDefault();
        const isloggedin = localStorage.getItem('isloggedin');
        const token = localStorage.getItem('token');
        const logout = await axios.get(APP_URL+'logout',{ headers: {"Authorization" : `Bearer ${token}`}});
        let js_data = JSON.parse(JSON.stringify(logout.data));
        
        if(js_data.status==1){            
            localStorage.clear();
            window.location.href="/";          
        }
    }

     const loaddynamicMenu = async()=>{
            try{
                const isloggedin = localStorage.getItem('isloggedin');
                const fetchmenu = await axios.get(APP_URL+'get-role-menu-permission/'+`${isloggedin}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                const loadmenus = JSON.parse(JSON.stringify(fetchmenu.data));                
                if(loadmenus.status==1){
                    setMenus(loadmenus.data.role_permission.menus);
                    let access_permission = loadmenus.data.role_permission.permissions.split(',');
                    setAccesspermission(access_permission);    
                }
                if(loadmenus.status==400){
                    setReNewRoute('/settings/pricing-plan');
                }else{
                    setReNewRoute('');
                }
            }catch(e){
                return false;
            }
        }

        const loadmenuusingpath=async()=>{
                try{                  
                    const url = window.location.href.split('#');
                    const url_search=url[1].split('?');                                    
                    const menu_list_data = await axios.get(APP_URL+'menu-list-search?slug='+`${url_search[0]}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                    const menus_data = JSON.parse(JSON.stringify(menu_list_data.data));
                    if(menus_data.status==1){                       
                      onloadpermission(menus_data.data.menu_list.id)
                    }                  

                }catch(e){
                    return null
                }
        }


        const onloadpermission=async(index)=>{
            try{
                    const permission_list=await axios.get(APP_URL+'permissions/menu/'+`${index}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                    const response_Data = JSON.parse(JSON.stringify(permission_list.data));
                    if(response_Data.status==1){
                        setPermissionlist(response_Data.data.permissions);
                    }
            }catch(e){
                return false;
            }
        }

      

    if(localStorage.getItem('isloggedin')==null){
        navigate('/');
    }else{

	return (

		 <header className="mb-5 fixed-top">
                <div className="header-top">
                    <div className="container">
                        <div className="logo">
                            <Link to="/dashboard">
                                <img src="https://closecall.in/corpbiz-call-api/uploads/logo-1683105181192.png"/>
                            </Link>
                        </div>
                        <div className="header-top-right">

                            <div className="dropdown">
                                <Link to="#" id="topbarUserDropdown" className="user-dropdown d-flex align-items-center dropend dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="avatar avatar-md2" >
                                        <img src={BASE_URL+"/assets/images/faces/1.jpg"} alt="Avatar" />
                                    </div>
                                    <div className="text">
                                        <h6 className="user-dropdown-name">{username}</h6>                                       
                                    </div>
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end shadow-lg" aria-labelledby="topbarUserDropdown"> 
                                  <li><Link className="dropdown-item" to="#" onClick={logout}>Logout</Link></li>
                                </ul>
                            </div>

                          
                            <Link to="#" className="burger-btn d-block d-xl-none">
                                <i className="bi bi-justify fs-3"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <nav className="main-navbar">
                    <div className="container">
                        <ul>                  
                            
                            {
                                menus.map((item,index)=>(
                       
                            <li
                                className={"menu-item "+(item.child.length!=0?'has-sub':null)}>
                                <Link to={item.slug} className='menu-link' >
                                    <i className="bi bi-stack"></i>
                                    <span>{item.menu_name}</span>
                                </Link>
                                {  item.child.length!=0?
                                <div className="submenu">
                                    <div className="submenu-group-wrapper">
                                        <ul className="submenu-group"> 
                                          {
                                          
                                            item.child.map((a,v)=>(                                           
                                             <li className="submenu-item">
                                                <Link to={a.slug}>{a.menu_name}</Link>
                                            </li>
                                            ))
                                           
                                        }

                                        </ul> 
                                    </div>
                                </div>
                                :null
                            }
                            </li>
                            ))
                        } 

                        {
                            reNewRoute!=''?
                            <>
                                 <li className="menu-item">
                                    <Link to={reNewRoute}  className='menu-link'>Pricing Plan</Link>
                                </li>
                            </>
                            :null
                        }                          
                            
                        </ul>


                    </div>
                </nav>

            </header>
           
		);
}
}

export default Topbar;