import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../../layout/topbar';
import Footer from '../../../layout/footer';
import {APP_URL,token} from '../../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginationcustom from '../../../validation/pagination';

const Permission=()=>{

const [listpermission,setListpermission] = useState([]);
const [permissionlist,setPermissionlist]=useState([]);
const [accesspermission,setAccesspermission]=useState([]);
const [menuids,setMenuids]=useState('');

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(10);
const [token,setToken]=useState(localStorage.getItem('token'));
useEffect(()=>{
	setToken(localStorage.getItem('token'));
	permission();
},[])

/*--pagination start here---*/ 

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = listpermission.slice(indexOfFirstRecord,indexOfLastRecord);

    const nPages = Math.ceil(listpermission.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);            
    } 

	/*--pagination start end here---*/

const permission = async()=>{
	try{
			const permission_data=await axios.get(APP_URL+'permissions-record',{ headers: {"Authorization" : `Bearer ${token}`}});
			let permission_list = JSON.parse(JSON.stringify(permission_data.data));
			if(permission_list.status==1){
				setListpermission(permission_list.data.permissions);
			}

	}catch(e){
		return false;
	}

}


const permission_Delete=async(index)=>{
		if(!window.confirm("Are you sure want to delete this")){
			return false
		}else{
			try{

				const menusdata_delete = await axios.delete(APP_URL+'permissions/'+`${index}`,{ headers: {"Authorization" : `Bearer ${token}`}});
				let menuslist = JSON.parse(JSON.stringify(menusdata_delete.data));
				if(menuslist.status==1){
					toast.success('Permission deleted successfull');
					permission();
				}else{
					toast.error('something went wrong');
				}

			}catch(e){
				return false;
			}
		}
	}

	return (
		<>
			<div id="app">
		        <div id="main" className="layout-horizontal">
		         <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>

		          <ToastContainer/>
					  <div className="content-wrapper container">           
					
					<div className="page-content">
					    <section className="row">    	
				        <div className="col-12 col-lg-12"> 
				        <section className="section">
				        <div className="row" id="table-hover-row">
				            <div className="col-12">
				                <div className="card">
				                    <div className="card-header">
				                    <h5>Manage Permission</h5>
				                    <div className="buttons mt-4">
				                    	{
                                    		 permissionlist && permissionlist.map((p,ind)=>(
         									  <>
         									  {
			                                    accesspermission.some(item=>p.id==item)==true?
			                           			<>				                                        	
                                    				{p.permission=='Add'?<Link to="/master/manage/add-permission"><button className="btn btn-warning rounded-pill"><i class="fa fa-plus"></i>Add New Permission</button></Link>:null}
                                    		
                                    		</>
                                    		:null
                                    		
                                    	}
                                    	</>
                                    		))
                                    	}
				                       							                       			
				                       	</div>		                   
				                    </div>
				                    <div className="card-body">                     
				                       	
				                        <div className="table-responsive pb-5">
				                            <table className="table table-bordered table-hover mb-0">
				                                <thead>
				                                    <tr>
				                                   	 	<th>Permission</th> 				                                    	
				                                    	<th>Menu</th>				                                    	                                  			                                       
				                                        <th>Status</th>			                                                                               
				                                        <th>ACTION</th>
				                                    </tr>
				                                </thead>
				                                <tbody>
				                                {
				                                	currentRecords && currentRecords.map((item,index)=>(

				                                    <tr>		                                    				                                        
				                                        <td>{item.permission}</td>
				                                        <td>{item.menu.menu_name}</td>
				                                       
				                                         <td>{item.status==1?<span className="badge bg-light-success" >Activate</span>:<span className="badge bg-light-danger" >Deactivate</span>}</td>
				                                        <td>
				                                        	<div className="buttons">
				                                        		{
						                                    		 permissionlist && permissionlist.map((p,ind)=>(
						         									  <>
						         									  {
									                                    accesspermission.some(item=>p.id==item)==true?
									                           			<>				                                        	
						                                    				{p.permission=='Activate'?<button className="btn btn-warning rounded-pill btn-custom" title="block number" ><i className="fa fa-ban"></i></button>:null}
				                                        					{p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" title="delete number" onClick={()=>permission_Delete(item.id)} ><i className="fa fa-trash"></i></button>:null}
						                                    		
						                                    		</>
						                                    		:null
						                                    		
						                                    	}
						                                    	</>
						                                    		))
						                                    	}
				                                        						                                        		
				                                        		
		                                        			</div>
				                                        </td>
				                                                                                
				                                    </tr> 
				                                    ))
				                                	
				                                  }                                 
				                                  
				                                </tbody>
				                            </table>
				                        </div>
				                         <div style={{textAlign:"right",float:"right"}}>
				                            <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
				                        </div>
				                    </div>
				                </div>
				            </div>
				        </div>
				    </section> 
				        </div>
				        
				    </section>
				</div>
				  <Footer/>
				 </div>
				         
				        </div>
		    </div>
		</>
		)
}



export default Permission;