import React,{useState  ,useEffect}  from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import axios from 'axios';
import {APP_URL,getdates} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
import AddLead from './AddLead';
import ModalTap from './Modal';
const LeadFuture=()=>{
const [highestData,setHighestData]=useState(null);
const [fromdate,setFromdate] =useState('');
const [todate,setTodate]=useState('');
const [employee,setEmployee]=useState('');
const [calltype,setCalltype] = useState('');
const [leadInboxList,setLeadInboxList] = useState([]);
const [employeelist,setEmployeelist]=useState([]);
const [leadTagList,setLeadTagList]=useState([]);
const [leadPowerList,setLeadPowerList]=useState([]);
const [permissionlist,setPermissionlist]=useState([]);
const [accesspermission,setAccesspermission]=useState([]);
const [menuids,setMenuids]=useState('');
const [token,setToken]=useState(localStorage.getItem('token'));
const [loading,setLoading]=useState(false);
const [selectedemp,setSelectedemp]=useState('');
const [addLeadStatus,setAddLeadStatus]=useState(false);
const [addcheckStatus,setAddcheckStatus]=useState(false);
const [leadID,setLeadID]=useState(null);
const [isEditLead,setIsEditLead]=useState(false);
const [isfilter,setIsfilter]=useState(false);

const [tagsID,setTagsID]=useState('');
const [employeeChange,setEmployeeChange]=useState('');
const [leadName,setLeadName]=useState('');
const [leadMobile,setLeadMobile]=useState('');
const [leadEmail,setLeadEmail]=useState('');
const [leadIDChange,setLeadIDChange]=useState('');
const [leadPowerChange,setLeadPowerChange]=useState('');
const [leadExportData,setLeadExportData]=useState([]);

const [leadAllocationID,setLeadAllocationID]=useState([]);
const [isModal,setIsModal]=useState(false);
const [isLeadData,setIsLeadData]=useState({
   leadstatus:0,
   updated_lead_id:0,
   updated_user_id:0
});
const {leadstatus,updated_lead_id,updated_user_id}=isLeadData;
const [currentPage, setCurrentPage] = useState(highestData!=null?highestData.page:1);
// const [recordsPerPage] = useState(highestData!=null?highestData.limit:null);
/*--pagination start here---*/ 
// const indexOfLastRecord = currentPage * recordsPerPage;
// const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
const currentRecords = leadInboxList;
const nPages = highestData!=null?highestData.total_page_count:null;
//const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
const changepage=(e,p)=>{
setCurrentPage(p); 
loadleadInbox(p);       
} 
useEffect(()=>{
loadusers();
loadleadTags();
loadleadPower();
loadleadInbox(1);
},[addcheckStatus]);

const handlefromdatechange = (e)=>{
setFromdate(e.target.value);
}
const handletodatechange = (e)=>{
setTodate(e.target.value);
}
/*---------handle employee filter change----*/
const handleemployeeChange = (e)=>{
setEmployeeChange(e.value);
}

/*-----------handle tags change--------*/
const handleTagsChange = (e)=>{
   setTagsID(e.value);
}

/*-------handle name change--------*/

const handleNameChange=(e)=>{
   setLeadName(e.target.value);
}

/*-----------handle mobile filter change-----------*/

const handleMobileChange=(e)=>{
   setLeadMobile(e.target.value);
}

/*----------handle email change filter-----*/

const handleEmailChange=(e)=>{
   setLeadEmail(e.target.value);
}

/*----------handle lead id filter change---------*/

const handleLeadIDChange=(e)=>{
   setLeadIDChange(e.target.value);
}

/*----------handle lead power change filter------*/

const handleLeadPowerflChange = (e)=>{
      setLeadPowerChange(e.value);
}

const handleemployeeAllocationchange = (e)=>{
setSelectedemp(e);
setEmployee(e.value);
}
const handlecallchange = (e)=>{
setCalltype(e.target.value);
}
const loadusers = async()=>{
   try{
      const users = await axios.get(APP_URL+'users',{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployeelist(users_list_data.data.user_list);
   }catch(e){
      return null;
   }
} 

/*--------load lead tags-------*/

const loadleadTags = async()=>{
   try{
      const users = await axios.get(APP_URL+'list-active-lead-tags',{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setLeadTagList(users_list_data.data.lead_tags);
   }catch(e){
      return null;
   }
} 

/*--------load lead power-------*/

const loadleadPower = async()=>{
   try{
      const users = await axios.get(APP_URL+'list-active-lead-power',{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setLeadPowerList(users_list_data.data.lead_tags);
   }catch(e){
      return null;
   }
} 

/*----load lead inbox---------*/


const loadleadInbox = async(page)=>{
   try{
      const users = await axios.get(APP_URL+'get-lead-future?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&tags='+`${tagsID}`+'&employee='+`${employeeChange}`+'&name='+`${leadName}`+'&mobile='+`${leadMobile}`+'&email='+`${leadEmail}`+'&lead_id='+`${leadIDChange}`+'&lead_power='+`${leadPowerChange}`+'&page='+`${page}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setLeadInboxList(users_list_data.data.lead_list);
      setHighestData(users_list_data.data);

       /*----------lead inbox export apis--------*/

      const users_export = await axios.get(APP_URL+'get-lead-future-export?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&tags='+`${tagsID}`+'&employee='+`${employeeChange}`+'&name='+`${leadName}`+'&mobile='+`${leadMobile}`+'&email='+`${leadEmail}`+'&lead_id='+`${leadIDChange}`+'&lead_power='+`${leadPowerChange}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data_export = JSON.parse(JSON.stringify(users_export.data));
      setLeadExportData(users_list_data_export.data.lead_list);
   }catch(e){
      return null;
   }
}

/*-----------handle click tab--------*/

const ontabchangepower = async(page,tagssid)=>{
      try{        
      const users = await axios.get(APP_URL+'get-lead-future?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&tags='+`${tagsID}`+'&employee='+`${employeeChange}`+'&name='+`${leadName}`+'&mobile='+`${leadMobile}`+'&email='+`${leadEmail}`+'&lead_id='+`${leadIDChange}`+'&lead_power='+`${tagssid}`+'&page='+`${page}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setLeadInboxList(users_list_data.data.lead_list);
      setHighestData(users_list_data.data);
      /*----------lead inbox export apis--------*/
      const users_export = await axios.get(APP_URL+'get-lead-future-export?from_date='+`${fromdate}`+'&to_date='+`${todate}`+'&tags='+`${tagsID}`+'&employee='+`${employeeChange}`+'&name='+`${leadName}`+'&mobile='+`${leadMobile}`+'&email='+`${leadEmail}`+'&lead_id='+`${leadIDChange}`+'&lead_power='+`${tagssid}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data_export = JSON.parse(JSON.stringify(users_export.data));
      setLeadExportData(users_list_data_export.data.lead_list);     

   }catch(e){
      return null;
   }
}

/*---------add lead---------*/

const add_lead_process=()=>{
   setLeadID(null);
   setAddLeadStatus(true);
   setIsEditLead(false);
}

/*----------view lead data------*/
const viewLeadDetails=(id)=>{
      setLeadID(id);
      setIsEditLead(true);
      setAddLeadStatus(true);
}

/*-------------filter button function------*/

const filterbtn=()=>{  
   let ele = document.querySelector('.filter_toggle');
   if (ele.style.display === "none") {
      ele.style.display = "block";
  }else {
     ele.style.display = "none";
  }
}

/*-----delete lead data-------*/

const delete_lead_data= async(index)=>{      
     let id=index;        
     if(!window.confirm('Are you sure want to delete this')){
         return false;
     }else{
         try{  
             setAddcheckStatus(false);             
             const delete_company_details = await axios.get(APP_URL+'delete-lead-details?id='+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
             var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
             if(js_data_delete_company.status==1){
             setAddcheckStatus(true);                
                 toast.success('lead deleted successfull');                   
             }
         }catch(e){
              toast.error('something went wrong');
         }
     }

 }

 /*-------------delete multiple selected lead-----------*/

 const delete_lead_data_multiple= async()=>{  
   if(leadAllocationID.length>0){        
     if(!window.confirm('Are you sure want to delete all')){
         return false;
     }else{
         try{  
             setAddcheckStatus(false);             
             const delete_company_details = await axios.get(APP_URL+'delete-multiple-lead-details?id='+`${leadAllocationID}`,{ headers: {"Authorization" : `Bearer ${token}`}});
             var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
             if(js_data_delete_company.status==1){
             setAddcheckStatus(true);                
                 toast.success('lead deleted successfull');                   
             }
         }catch(e){
              toast.error('something went wrong');
         }
     }
  }else{
    toast.error("please select lead");
  }

 }

 /*--------lead allocation check all value and uncheck all-------*/

 const leadAllocationEmployee=()=>{
      let ele=document.querySelector('.checkAll');
      let checkall = document.querySelectorAll('.checkAllLead');      
      if(ele.checked===true){
         let arr=[];
        for(var i=0;i<checkall.length;i++){
            checkall[i].checked=true;
            arr.push(checkall[i].value);
        }
         setLeadAllocationID(arr);
      }else{
        for(var i=0;i<checkall.length;i++){
            checkall[i].checked=false;
            setLeadAllocationID([]);
        }
      }
 }

 /*-----------single check and uncheck lead------*/

 const handleCheckboxLead=(e)=>{
      if(e.target.checked==true){
         setLeadAllocationID([...leadAllocationID,e.target.value]);
      }else{         
         leadAllocationID.splice(leadAllocationID.indexOf(e.target.value),1);
      }      
 } 

 /*---------lead allocation submit-------*/

 const allocation_lead_employee= async()=>{ 
      if(leadAllocationID.length>0 && employee!=''){
         try{  
             setAddcheckStatus(false);  
             var form = new FormData();
             form.append('lead_id',leadAllocationID);
             form.append('emp_id',employee);           
             const delete_company_details = await axios.post(APP_URL+'lead-allocation',form,{ headers: {"Authorization" : `Bearer ${token}`}});
             var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
             if(js_data_delete_company.status==1){
               setAddcheckStatus(true);                
               toast.success('lead assigned successfull');                   
             }
         }catch(e){
              toast.error('something went wrong');
         }
      }else{
         toast.error('please select lead and employee');
      }
 }

   /*-----------copy lead id-------------*/

const copyleadid=async(index)=>{
  let id = index;       
  var TempText = document.createElement("input");
  TempText.value = id;
  document.body.appendChild(TempText);
  TempText.select();
  document.execCommand('copy');
  toast.success('copied successfull');
  document.body.removeChild(TempText);
}
   

const employeedata=[];
employeelist && employeelist.forEach(item=>{
employeedata.push({value:item.id,label:item.name+'-'+item.user_unique_code});
})

const leadtagdata=[];
leadTagList && leadTagList.forEach(item=>{
leadtagdata.push({value:item.id,label:item.tags});
})

const leadpowerdata=[];
leadPowerList && leadPowerList.forEach(item=>{
leadpowerdata.push({value:item.id,label:item.power_name});
})

/*---------export lead in csv format------*/

const headers = [
   {  label: "Lead ID", key: "lead_id" },
   {  label:"Customer Name",key:"cust_name"},               
   {  label: "Customer Email", key: "cust_email"},
   {  label: "Customer Mobile",key:"cust_mobile"},               
   {  label:"Tags",key:"tags"},               
   {  label:"Address",key:"address"},              
   {  label:"City",key:"city"},
   {  label:"State",key:"state"},
   {  label:"Country",key:"country"},
   {  label:"Pincode",key:"pincode"},
   {  label:"Language",key:"lang"},
   {  label:"Status",key:"status"},
   {  label:"Follow Up Date",key:"follow_up_date"},
   {  label:"Lead Date",key:"lead_date"},                        
];
const data = [];
leadExportData && leadExportData.forEach((item)=>{
   data.push({lead_id:item.id,
            cust_name:item.name,
            cust_email:item.email,
            cust_mobile:item.prefix_mob+item.mobile,
            tags:item.service_tag!=null?item.service_tag.tags:'',
            address:'',
            city:'',
            state:'',
            country:'',
            pincode:'',
            lang:'',
            status:item.lead_power_status!=null?item.lead_power_status.power_name:'',
            follow_up_date:item.follow_up_date,
            lead_date:item.lead_date
   })
})
/*---------------modal enable----------*/
const OpenModal=(status,lead_id,user_id)=>{
   setIsLeadData({...isLeadData,leadstatus:status,updated_lead_id:lead_id,updated_user_id:user_id});
   setIsModal(true);
}

const CloseModal=()=>{
   setIsModal(false);
}
return(
<>
<div id="app">
   <div id="main" className="layout-horizontal">
   <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
      <ToastContainer/>
      {addLeadStatus==true?<><AddLead addLeadStatus={addLeadStatus} setAddLeadStatus={setAddLeadStatus} leadID={leadID} setLeadID={setLeadID} isEditLead={isEditLead} setIsEditLead={setIsEditLead} addcheckStatus={addcheckStatus} setAddcheckStatus={setAddcheckStatus}/></>:<>
      <div className="content-wrapper container">
         <div className="page-content">
            <section className="row">
               <div className="col-12 col-lg-12">
                  <section className="section">
                     <div className="row" id="table-hover-row">
                        <div className="col-12">
                           <div className="card">
                              <div className="card-header" style={{borderBottom:'1px solid'}}>
                              <h5>Lead Future ({highestData && highestData.total_data})</h5>
                           </div>
                           <div className="card-body">
                              <div className="lead_btn_group mt-4 mb-4">
                                 <div className="btn-group btn-group-sm w-100" role="group" aria-label="Basic mixed styles example">
                                
                                    {
                                       leadPowerList && leadPowerList.map((item)=>(
                                          <>
                                             <button type="button" className="btn btn-danger" onClick={()=>ontabchangepower(1,item.id)}>{item.power_name}
                                             <br/>
                                             <span className="badge rounded-pill text-bg-light">{highestData && highestData.lead_power_count.some((it)=>it.lead_power==item.id)==true?highestData.lead_power_count.map((ele)=>(ele.lead_power==item.id?ele.total:null)):0}</span>
                                             </button>
                                          </>
                                          ))
                                    }                                   
                                   
                                 </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                               {/*<div className="col-3">                                   
                                    <select className="form-select" aria-label="Default select example">
                                       <option value="0">Sort By</option>
                                       <option value="1">One</option>
                                       <option value="2">Two</option>
                                       <option value="3">Three</option>
                                    </select>
                                 </div>
                                 */
                              }
                              <div className="text-end">
                                 <button className="btn btn-secondary" onClick={filterbtn}><i className="fa fa-filter"></i> Filter</button>
                              </div>
                              </div>
                              <hr/>
                              <div className="row filter_toggle" style={{'display':'none'}}>
                                 <div className="d-flex align-items-end">
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       From Date
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="date"
                                          name="fromdate"
                                          onChange={(e)=>handlefromdatechange(e)}
                                          />
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       To Date
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="date"
                                          name="todate"
                                          onChange={(e)=>handletodatechange(e)}
                                          />
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Tags
                                       </label>
                                       <Select options={leadtagdata} onChange={(e)=>handleTagsChange(e)}/>                                          
                                     
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Employee
                                       </label>
                                       <Select options={employeedata} onChange={(e)=>handleemployeeChange(e)}/>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-end mt-2">
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Name
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="text"
                                          name="name"
                                          onChange={(e)=>handleNameChange(e)}
                                          />
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Phone
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="text"
                                          name="phone"
                                          onChange={(e)=>handleMobileChange(e)}
                                          />
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Email
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="email"
                                          name="email"
                                          onChange={(e)=>handleEmailChange(e)}
                                          />
                                    </div>
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Lead ID
                                       </label>
                                       <input
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          type="text"
                                          name="leadid"
                                          onChange={(e)=>handleLeadIDChange(e)}
                                          />
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-end mt-2 pb-4">
                                    <div className="col-3 pe-4">
                                       <label className="form-label ps-0" for="formGroupExampleInput">
                                       Lead Power
                                       </label>
                                       <Select options={leadpowerdata} onChange={(e)=>handleLeadPowerflChange(e)}/>
                                    </div>
                                    <div className="col-3 pe-4">
                                       <button type="button" className="btn btn-success" onClick={()=>loadleadInbox(1)}><i className="fa fa-search me-2"></i>Search</button>
                                    </div>
                                 </div>
                                 <hr/>
                              </div>
                              
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                                       <div>

                                           {
                                                permissionlist && permissionlist.map((p,ind)=>(
                                                          <>
                                                          {
                                                            accesspermission.some(item1=>p.id==item1)==true?
                                                            <>  
                                                                {p.permission=='Add Lead'? <button type="button" className="btn btn-success btn-sm me-2" onClick={add_lead_process}><i className="fa fa-plus me-1"></i>Add Lead</button>:null}                                                        
                                                                {p.permission=='Multiple Delete'? <button type="button" className="btn btn-danger btn-sm me-2" onClick={delete_lead_data_multiple}><i className="fa fa-trash me-1"></i>Delete Selected</button>:null}
                                                                {p.permission=='Import'?<button type="button" className="btn btn-warning btn-sm me-2"><i className="fa fa-upload me-1"></i>Import Lead</button>:null}
                                                                {p.permission=='Export'? <CSVLink
                                                                                       data={data}                                                   
                                                                                       headers={headers}
                                                                                       filename={"export-lead.csv"}
                                                                                       className="btn btn-secondary btn-sm"
                                                                                       target="_blank"
                                                                                       >
                                                                                       <i className="fa fa-download"></i> Export Lead
                                                                                    </CSVLink>:null}                                                             
                                                        
                                                        </>
                                                        :null
                                                        
                                                    }
                                                    </>
                                                   ))
                                             }                                
                                                         
                                         
                                          
                                          
                                       </div>
                                       <div className="col-4">                                  
                                   
                                    <div className="d-flex">
                                       <Select options={employeedata} value={selectedemp} onChange={(e)=>handleemployeeAllocationchange(e)} className="me-2 custom_select"/>
                                       <button className="btn btn-secondary" type="button" onClick={allocation_lead_employee}>Allocate</button>
                                    </div>
                                 </div>
                                    </div>
                                    <div className="table-responsive pb-5">
                                       <table className="table table-bordered">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" className="checkAll" onClick={leadAllocationEmployee}/></th>
                                                <th style={{width:"200px"}}>Agent name</th>
                                                <th>Lead Info</th>
                                                <th>Tags</th>
                                                <th>Note</th>
                                                <th>Status</th>
                                                <th>Follow Up Date</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {
                                             loading==true?<>
                                             <tr>
                                                <td colSpan="11">
                                                   <Loader/>
                                                </td>
                                             </tr>
                                             </>:
                                             <>
                                             {
                                             currentRecords && currentRecords.map((item,index)=>(
                                             <tr>
                                                <td><input type="checkbox" className="checkAllLead" value={item.id} onClick={(e)=>handleCheckboxLead(e)}/></td>
                                                <td>{item.lead_assign_user!=null?item.lead_assign_user.name:'Not Assigned'}</td>
                                                <td>{item.name}<br/>{item.prefix_mob+item.mobile}<br/>{item.email_id}</td>
                                                <td>{item.service_tag.tags}</td>
                                                <td>{item.message}</td>
                                               <td>{item.lead_power_status!=null?<><span className="badge bg-light-success">{item.lead_power_status.power_name}</span></>:<><span className="badge bg-light-warning">Pending</span></>}</td>
                                                <td>{item.follow_up_date}</td>
                                                <td>{item.lead_date}</td>
                                                <td>
                                                 <div className="buttons">
                                                           
                                                {
                                                      permissionlist && permissionlist.map((p,ind)=>(
                                                          <>
                                                          {
                                                            accesspermission.some(item1=>p.id==item1)==true?
                                                            <>  
                                                                {p.permission=='FollowUp'?<Link to={item.lead_assign_user!=null?'/lead/lead-follow-up?leadID='+`${item.id}`:'#'}><button className="btn btn-success rounded-pill btn-custom" ><i className="fa fa-sitemap"></i></button></Link>:null}                                                        
                                                                {p.permission=='Edit'?<button className="btn btn-success rounded-pill btn-custom" onClick={()=>viewLeadDetails(item.id)}><i className="fa fa-pencil"></i></button>:null}
                                                                {p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={()=>delete_lead_data(item.id)}><i className="fa fa-trash"></i></button>:null}
                                                                {p.permission=='SMS'?<Link to={item.lead_assign_user!=null?'/lead/lead-follow-up?leadID='+`${item.id}`:'#'}><button className="btn btn-primary rounded-pill btn-custom" ><i className="fa fa-comment"></i></button></Link>:null}
                                                                {p.permission=='Email'?<Link to={item.lead_assign_user!=null?'/lead/lead-follow-up?leadID='+`${item.id}`:'#'}><button className="btn btn-warning rounded-pill btn-custom" ><i className="fa fa-envelope"></i></button></Link>:null}
                                                                {p.permission=='Negative'?<button className="btn btn-danger rounded-pill btn-custom" title="mark as negative" onClick={()=>OpenModal(1,item.id,item.assing_id)}><i className="fa fa-thumbs-down"></i></button>:null} 
                                                                {p.permission=='Completed'?<button className="btn btn-success rounded-pill btn-custom" title="mark as completed" onClick={()=>OpenModal(2,item.id,item.assing_id)}><i className="fa fa-thumbs-up"></i></button>:null}                                                               
                                                                {p.permission=='Copy'?<button className="btn btn-warning rounded-pill btn-custom" onClick={(e)=>copyleadid(item.encrypt_lead_id)} title="copy user id"><i className="fa fa-copy"></i></button>:null}  
                                                        </>
                                                        :null
                                                        
                                                    }
                                                    </>
                                                        ))
                                                    }

                                                
                                            </div>
                                            </td>
                                             </tr>
                                             ))
                                             }
                                             </>
                                             }
                                          </tbody>
                                       </table>
                                    </div>
                                    <div style={{textAlign:"right",float:"right"}}>
                                       <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
               </div>
               </section> 
         </div>
         </section>
      </div>
      <Footer/>
      {isModal==true?
       <ModalTap CloseModal={CloseModal} isLeadData={isLeadData} isModal={isModal} setIsModal={setIsModal} setAddcheckStatus={setAddcheckStatus}/> 
       :null 
      }
   </div>
   </>
}
</div>
</div>
</>
)
}
export default LeadFuture;