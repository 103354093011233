import React from 'react';

const Navstep=({step,role_type})=>{

		return(<>
			 <div id="wizard-progress">
			    <ol className="step-indicator">
			        <li className={"nvstep "+(step==1?'active':'')}>
			            <div className="step"><i className="iconly-boldAdd-User"></i></div>
			            <div className="caption hidden-xs hidden-sm">Basic Info</div>
			        </li>
			        <li className={"nvstep "+(step==2?'active':'')}>
			            <div className="step"><i className="iconly-boldUser"></i></div>
			            <div className="caption hidden-xs hidden-sm">Select Role</div>
			        </li>
			       
			        <li className={"nvstep "+(step==3?'active':'')}>
			            <div className="step"><i className="iconly-boldUser"></i></div>
			            <div className="caption hidden-xs hidden-sm">Select Reporting Head</div>
			        </li>
			    </ol>
			</div>
			</>
			);	
}

export default Navstep;