import React from 'react';
import {useState,useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import BasicFrm from './basicinfoFrm';
import SelectEmp from './selectempFrm';
import Navstep from './Navstep';
import Setpermission from './Setpermissionfrm';
import Summary from './Summary';
import axios from 'axios';
import {APP_URL} from '../../validation/basic'
const FinalFrm=({edituser,setEdituser})=>{	
	 const [step, setStep] = useState(1);
	 const [token,setToken]=useState(localStorage.getItem('token'));
	 const [responses,setResponses]=useState();
	 const [user,setUser] = useState({
	 	  company_id:0,		
	 	  department_id:0,		
          name:'',
          mobile:'',
          email:'',
          is_password:1,
          usepassword:'',
          cnfpassword:'',         
          roll_id:0,
          reporting:0,
          is_masking:1,
          is_allow:1                
    })

	 let {id} = useParams();

	
    const {company_id,department_id,name,mobile,email,is_password,usepassword,cnfpassword,roll_id,reporting,is_masking,is_allow}=user;
	useEffect(()=>{
		 setToken(localStorage.getItem('token')); 
		loadusers();
	},[]);

	const loadusers = async()=>{
        try{
            const users = await axios.get(APP_URL+'users/'+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            let users_list_data = JSON.parse(JSON.stringify(users.data));
            const user_response = users_list_data.data.User;
           	if(users_list_data.status==1){
           		setUser({...user,company_id:user_response.company_id,department_id:user_response.department_id,name:user_response.name,mobile:user_response.mobile,email:user_response.email,roll_id:user_response.roll_id,reporting:user_response.reporting});
           	}          
        }catch(e){
            return null;
        }

    }

	 
	 const nextStep = () => {
	    setStep(step + 1);	    
	  };

    const prevStep = () => {
	    setStep(step - 1);
	  };



  switch(step){
	  	case 1 :
	  	return (
	  		<>
	  		<Navstep step={step}/>
	  		<BasicFrm nextStep={nextStep} user={user} setUser={setUser} edituser={edituser} setEdituser={setEdituser}/>
	  		</>
	  		);

	  	case 2:

	  	return (
	  	<>
	  		<Navstep step={step} />
	  		<SelectEmp nextStep={nextStep} prevStep={prevStep} user={user} setUser={setUser} edituser={edituser} setEdituser={setEdituser}/>
	  		</>
	  		);

  		case 3:
  			if(user.employee_type==1){
  				console.log(user.employee_type)
			  	return (
			  	<>
			  		<Navstep step={step} role_type={user.employee_type}/>
			  		<Setpermission nextStep={nextStep} prevStep={prevStep} user={user} setUser={setUser} edituser={edituser} setEdituser={setEdituser}/>
			  		</>
			  		);
			  	}
  	case 4:
  	return(
  		<>
  			<Navstep step={step} role_type={user.employee_type}/>
  			<Summary prevStep={prevStep} user={user} setUser={setUser} edituser={edituser} setEdituser={setEdituser} id={id}/>
  		</>
  	);	

	  	default:
      return (
       <h1></h1>
      );
  	}



}

export default FinalFrm;