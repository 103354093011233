import React,{useState,useEffect,useCallback} from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import {APP_URL,token} from '../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
const Excludenumber = ()=>{
	const [employees,setEmployees]=useState([]);
	const [departments,setDepartments]=useState([]);
	const [excludeno,setExcludeno] = useState({
			data:[]
	})
 const [token,setToken]=useState(localStorage.getItem('token'));

const [devices,setDevices] = useState([]);
const [selected,setSelected]=useState();
const [employee,setEmployee]=useState('');
const [department,setDepartment]=useState('');
const [status,setStatus]=useState('');
const [selectedemp,setSelectedemp]=useState('');
const{data}=excludeno;
const [loading,setLoading]=useState(false);
const [permissionlist,setPermissionlist]=useState([]);
const [accesspermission,setAccesspermission]=useState([]);
const [menuids,setMenuids]=useState('');

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(5);

/*--pagination start here---*/ 

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = devices.slice(indexOfFirstRecord,indexOfLastRecord);

    const nPages = Math.ceil(devices.length / recordsPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);            
    } 

	/*--pagination start end here---*/

const excludephone = async()=>{
			try{
					setLoading(true);
					const user_devices = await axios.get(APP_URL+'user-devices',{ headers: {"Authorization" : `Bearer ${token}`}});
					let user_device_list = JSON.parse(JSON.stringify(user_devices.data));
					if(user_device_list.status==1){
							setDevices(user_device_list.data.user_device);
							setLoading(false);
					}

			}catch(e){
				return null;
			}
	}

		useEffect(()=>{
		setToken(localStorage.getItem('token'));
		excludephone();
		loadusers();
		loadDepartment();
	},[]);

		 const loadusers = async()=>{
		        try{
		            const users = await axios.get(APP_URL+'users',{ headers: {"Authorization" : `Bearer ${token}`}});
		            let users_list_data = JSON.parse(JSON.stringify(users.data));
		            setEmployees(users_list_data.data.user_list);
		        }catch(e){
		            return null;
		        }

		    }
		    const loadDepartment=async()=>{
		        const department_details = await axios.get(APP_URL+'departments',{ headers: {"Authorization" : `Bearer ${token}`}});
		        var js_data_department = JSON.parse(JSON.stringify(department_details.data));
		        if(js_data_department.status==1){
		            setDepartments(js_data_department.data.departments);
		        }

		   }  


		const device_status_change =async(index)=>{
			if(!window.confirm('Are you sure want to change this')){
				return false;
			}else{
				try{
						const device_change = await axios.get(APP_URL+'user-devices/status/'+`${index}`,{ headers: {"Authorization" : `Bearer ${token}`}});
						let status_change = JSON.parse(JSON.stringify(device_change.data));
						if(status_change.status==1){
								toast.success('Device Status Change Successfull');
								excludephone();
						}else{
							toast.error('something went wrong');
						}
				}catch(e){
					toast.error('something went wrong');
				}
			}
		}


		const assign_no_delete = async(index)=>{	
				
				
				if(!window.confirm('Are you sure want to delete this')){
					return false;
				}else{
					 try{

					 			let setdata = {
															'data':[{'id':index}]
														}				 			
					 			const delete_assign = await axios.post(APP_URL+'user-devices/delete',setdata,{ headers: {"Authorization" : `Bearer ${token}`}});
					 			let deleted_data = JSON.parse(JSON.stringify(delete_assign.data));
					 			if(deleted_data.status==1){
					 					toast.success('Deleted successfull');
					 					excludephone();
					 			}else{
					 					toast.error('something went wrong');
					 			}
					 }catch(e){
					 			toast.error('something went wrong');
					 }
				}
		}


		const assign_selected_exclude=async()=>{
				if(!window.confirm('Are you sure want to exclude this')){
						return false;
				}else{
						try{

								var setdata = excludeno;
								if(setdata.data.length!=0){
								const exclude = await axios.post(APP_URL+'user-devices/multiple-status',setdata,{ headers: {"Authorization" : `Bearer ${token}`}});
								let exclude_Data = JSON.parse(JSON.stringify(exclude.data));
								if(exclude_Data.status==1){
										toast.success('excluded successfull');
										excludephone();
								}else{
									toast.error('something went wrong');
									return false;
								}
							}else{
								toast.error('please select number to exclude');
								return false;
							}

						}catch(e){
								toast.error('something went wrong');
								return false;
						}
				}
		}

		const check_all=(e)=>{
				if(e.target.checked){										
					setSelected(true);					
				}else{
					setSelected(false);
				}
		}

		const handlechange=(e)=>{
			const {value,checked} =e.target;
			const {data}=excludeno;	
			if(checked){
				 setExcludeno({data:[...data,{'id':value}]})
			}else{
				data:data.filter((e)=>e!=={'id':value})
			}

		}



		const assign_selected_delete=async()=>{
				if(!window.confirm('Are you sure want to delete selected')){
						return false;
				}else{
						try{

								var setdata = excludeno;
								if(setdata.data.length!=0){
								const exclude = await axios.post(APP_URL+'user-devices/delete',setdata,{ headers: {"Authorization" : `Bearer ${token}`}});
								let exclude_Data = JSON.parse(JSON.stringify(exclude.data));
								if(exclude_Data.status==1){
										toast.success('deleted successfull');
										excludephone();
								}else{
									toast.error('something went wrong');
									return false;
								}
							}else{
								toast.error('please select number to delete');
								return false;
							}

						}catch(e){
								toast.error('something went wrong');
								return false;
						}
				}
		}

		const handlereset = async(index,device_token)=>{
			if(!window.confirm("Are you sure want to reset this device, if you confirm then all device data will be deleted !")){
					return false;
			}else{
				try{
						let action  = '{"action": "wipe_data"}';
						var form = new FormData();
						form.append('device_token',device_token);
						form.append('action',action);
						const firsbase = await axios.post(APP_URL+'firebase-send',form,{ headers: {"Authorization" : `Bearer ${token}`}});
						let fireresponse = JSON.parse(JSON.stringify(firsbase.data));
						if(fireresponse.status==1){
								toast.success('device format successfull');
						}

				}catch(e){
						return false;
				}
			}
		}

		const exclude_device=async(index,device_token,number,agent_status)=>{
			if(!window.confirm("Are you sure want to exclude this")){
					return false;
			}else{
					try{
								let status = agent_status;							
								let action  = '{"action": "exclude_number","number":"'+number+'","status":"'+status+'"}'
								let form = new FormData();
								form.append('device_token',device_token);
								form.append('action',action);
								const hitfirebase = await axios.post(APP_URL+'exclude-number-firebase',form,{ headers: {"Authorization" : `Bearer ${token}`}});
								let response = JSON.parse(JSON.stringify(hitfirebase.data));
								if(response.status==1){
									toast.success('No Excluded successfull');
									excludephone();
								}

					}catch(e){
						return false;
					}
			}

	}

	const employeedata=[];
        employees.forEach(item=>{
            employeedata.push({value:item.id,label:item.name+'-'+item.user_unique_code});

        }) 

	const handleemployee=(e)=>{
		 setSelectedemp(e); 
		setEmployee(e.value);
	}

	const handledepartment=(e)=>{
		setDepartment(e.target.value);
	}

	const handlestatus=(e)=>{
		setStatus(e.target.value);
	}



    /*----user filter----*/
    const user_device_filter=async()=>{
        try{
        		setLoading(true);
                const user_lists = await axios.get(APP_URL+'user-device-filter-crm?department='+`${department}`+'&employee='+`${employee}`+'&status='+`${status}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                let user_lists_data = JSON.parse(JSON.stringify(user_lists.data));
                if(user_lists_data.status==1){
                     setDevices(user_lists_data.data.user_device);
                     setLoading(false);
                }
        }catch(e){
            return false;
        }
    } 

		
	return(
		<>
			<div id="app">
        <div id="main" className="layout-horizontal">
         <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>

          <ToastContainer/>
			  <div className="content-wrapper container">           
			
			<div className="page-content">
			    <section className="row">    	
		        <div className="col-12 col-lg-12"> 
		        <section className="section">
		        <div className="row" id="table-hover-row">
		            <div className="col-12">
		                <div className="card">
		                    <div className="card-header" style={{borderBottom:'1px solid'}}>
		                    	<h5>Assign Phone Numbers</h5>		                   	                   
		                    </div>
		                    <div className="card-body">
		                     <div className="row">
		                    	<div className="col-lg-12">
		                    		<table className="table table-borderless mb-3">
		                    			<thead>
		                    				<tr>
		                    					<th>Select employee</th>
		                    					<th>Enter Assign no</th>
		                    					<th>Select status</th>
		                    					<th></th>
		                    				</tr>
		                    			</thead>
		                    			<tbody>
		                    				<tr>
		                    				 <td style={{width:'250px'}}>
                                                <Select                                     
                                                    value={selectedemp}
                                                    onChange={(e)=>handleemployee(e)}
                                                    options={employeedata}
                                                  />
                                                    
                                                </td>
		                    					<td>
		                    						<input type="text" className="form-control" onChange={(e)=>handledepartment(e)}/>	                    							
		                    					
		                    					</td>
		                    					<td>
		                    						<select className="form-control" onChange={(e)=>handlestatus(e)}>
		                    							<option value="">Select status</option>
		                    							<option value="1">Activate</option>
		                    							<option value="0">Deactivate</option>
		                    						</select>
		                    					</td>
		                    					<td><button className="btn btn-success" onClick={user_device_filter}>Apply</button></td>
		                    				</tr>
		                    			</tbody>
		                    		</table>
		                    	</div>
		                    </div>
		                    <div className="buttons">
		                    {
                            permissionlist && permissionlist.map((p,ind)=>(
                             <>
                                {
                                    accesspermission.some(item=>p.id==item)==true?
                           			<>
		                       			{p.permission=='Activate Multiple'?<button className="btn btn-warning rounded-pill " onClick={(e)=>assign_selected_exclude(e)}><i class="fa fa-ban"></i> Deactivate Selected No</button>:null}
		                       			{p.permission=='Delete Multiple'?<button className="btn btn-danger rounded-pill " onClick={(e)=>assign_selected_delete(e)}><i class="fa fa-trash"></i> Delete Selected No</button>:null}
		                       			</>
		                       			:null
		                       			
		                       		}
		                       		</>
		                       			))
		                       		}
		                       	</div>	                     
		                       	
		                        <div className="table-responsive pb-5">
		                            <table className="table table-bordered table-hover mb-0">
		                                <thead>
		                                    <tr>

		                                    	<th><input type="checkbox" onClick={check_all}/></th>
		                                    	<th>Assign No</th>
		                                    	<th>User Code</th>		                                       
		                                        <th>User Name</th>
		                                        
		                                        <th>Device Info</th>
		                                        <th>Is Exclude</th>
		                                        <th>Status</th>		                                                                               
		                                        <th>ACTION</th>
		                                    </tr>
		                                </thead>
		                                <tbody>
		                                {
		                                	loading==true?<><tr><td colSpan="8"><Loader/></td></tr></>:
		                                    <>
		                                    {

		                                	currentRecords !=null?
		                                	 currentRecords && currentRecords.map((item,index)=>(

		                                    <tr>		                                    				                                        
		                                        <td><input type="checkbox" name="id" value={item.id}  onChange={(e)=>handlechange(e)} checked={selected}/></td>
		                                        <td><Link>{item.agent_phone_number}</Link></td>
		                                        <td>{item.user.user_unique_code}</td>
		                                        <td>{item.user.name}</td>
		                                        
		                                        <td>
		                                        	{ 
		                                        		item.device_information!=null?
		                                        		<div>                                       			                                      
		                                        		<strong>Device Id - </strong>{item.device_id}<br/>
		                                        		<strong>Model - </strong>{item.device_information.model}<br/>
		                                        		<strong>Brand - </strong>{item.device_information.brand}<br/>
		                                        		<strong>Android Version - </strong>{item.device_information.android_version}<br/>
		                                        		<strong>Last Reset - </strong>{item.last_reset}
		                                        		</div>
		                                        		:null
		                                        	
		                                        	}
		                                        	
		                                        </td>
		                                        <td>{item.is_exclude==0?<span className="badge bg-light-success" onClick={()=>exclude_device(item.id,item.device_token,item.agent_phone_number,1)}>No</span>:<span className="badge bg-light-danger" onClick={()=>exclude_device(item.id,item.device_token,item.agent_phone_number,0)}>Yes</span>}</td>
		                                         <td>{item.status==1?<span className="badge bg-light-success" >Activate</span>:<span className="badge bg-light-danger" >Deactivate</span>}</td>
		                                        <td>
		                                        	<div className="buttons">
		                                        	{
							                            permissionlist && permissionlist.map((p,ind)=>(							                            
							                             <>
							                              {
						                                    accesspermission.some(item=>p.id==item)==true?
						                           			<>
		                                        		{p.permission=='Activate'?<button className="btn btn-warning rounded-pill btn-custom" title="block number" onClick={(e)=>device_status_change(item.id)}><i className="fa fa-ban"></i></button>:null}
		                                        		{p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" title="delete number" onClick={(e)=>assign_no_delete(item.id)}><i className="fa fa-trash"></i></button>:null}
		                                        		{p.permission=='Reset'?
		                                        			<>
			                                        		{
			                                        			item.status==1?<button class="btn btn-primary rounded-pill btn-custom" title="Erase All Device Data" onClick={(e)=>handlereset(item.id,item.device_token)}><i className="fa fa-refresh"></i></button>:null
			                                        		}
			                                        		</>
		                                        			:null
		                                        		}
		                                        		</>
		                                        		:null
		                                        	}
		                                        	</>
		                                        		))
		                                        	}
                                        			</div>
		                                        </td>
		                                                                                
		                                    </tr> 
		                                    ))
		                                	 :null
		                                  }
		                                  </>
		                                  }                                 
		                                  
		                                </tbody>
		                            </table>
		                        </div>
		                         <div style={{textAlign:"right",float:"right"}}>
		                            <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section> 
		        </div>
		        
		    </section>
		</div>
		  <Footer/>
		 </div>
		         
		        </div>
    </div>
		</>
		);
}

export default Excludenumber;