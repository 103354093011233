import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {APP_URL} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Loader from '../../loader/loader';
const Nevercomponent = ({from_date,to_date,calltype,employee,neverattended,setNeverattended,loading,neverData,NeverAttendedListData,neverExportData,neverAttendedReportExport})=>{

	const [currentPage, setCurrentPage] = useState(neverData!=null?neverData.page:1);
    // const [recordsPerPage] = useState(neverData!=null?neverData.limit:null);
    /*--pagination start here---*/ 

    // const indexOfLastRecord = currentPage * recordsPerPage;
    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = neverattended;

    const nPages = neverData!=null?neverData.total_page_count:null;
    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const changepage=(e,p)=>{
        setCurrentPage(p);        
        NeverAttendedListData(parseInt(p),10)           
    } 
	/*--pagination start end here---*/ 
	
	return (
		<>
			<div className="row mt-3">
					<div className="col-lg-12">
							<h6>Never Attended Summary ({neverData && neverData.total_data})</h6>
					</div>
					<div className="col-lg-12">
					<button						  
						  className="btn btn-success"
						  onClick={neverAttendedReportExport}
						>
				  <i className="fa fa-download"></i> Export
				</button> 
							<table className="table table-bordered">
									<thead>
											<tr>
													<th rowSpan="2" style={{width:"200px"}}>Agent name</th>
													<th rowSpan="2">Agent code</th>
													<th rowSpan="2">Assign No</th>
													
													<th>
														<div className="row">
															<div className="col-sm-4">
																Client No
															</div>
															<div className="col-sm-3">
																Call Type
															</div>
															<div className="col-sm-3">
															Date
															</div>
															<div className="col-sm-2">
															Time
															</div>
														</div> 

													</th>
																										
													<th rowSpan="2">Attempt to call</th>
											</tr>
									</thead>
									<tbody>
									{
										loading==true?<><tr><td colSpan="5"><Loader/></td></tr></>:
						              	<>
						              	{	
										currentRecords && currentRecords.map((item,index)=>(
											<tr>
													<td>{item.agent_name}</td>
													<td>{item.agent_code}</td>
													<td>{item.agent_contact_no}</td>													
													<td>
															
																<div className="row">
																{	
																item.call_details.map((a,v)=>(
																	<>
																		<div className='col-sm-4'>{a.client_update_contact_no}</div>
																		<div className='col-sm-3'>{a.status==3?<span style={{color:"red"}}>Missed Call</span>:null}</div>
																		<div className='col-sm-3'>{a.call_date}</div>
																		<div className='col-sm-2'>{a.call_time}</div>
																		</>
																	))
																}
																</div>
															
													</td>
													
													<td>0</td>
											</tr>
											))
										}
										</>
									}
									</tbody>
							</table>
							 <div style={{textAlign:"right",float:"right"}}>
									 <Paginationcustom data={nPages} changepage={changepage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
	                        </div>
					</div>
			</div>
		</>
		)
}

export default Nevercomponent;