import ReactDOM,{useState	,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import axios from 'axios';
import {APP_URL,getdates} from '../../validation/basic';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
const MailTemplate=()=>{
	
	const [template,setTemplate]=useState([]);
	const [token,setToken]=useState(localStorage.getItem('token'));
	const [selectedemp,setSelectedemp]=useState('');
	const [loading,setLoading]=useState(false);
	const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
	const [checkAdd,setCheckAdd]=useState(false);
	useEffect(()=>{
		setToken(localStorage.getItem('token'));
		loadTemplate();
	},[checkAdd]); 

	/*-----------load template data-------*/
	const loadTemplate=async()=>{
	 	try{     
	        const company_list = await axios.get(APP_URL+'list-lead-template',{ headers: {"Authorization" : `Bearer ${token}`}});
	        let companiesList = JSON.parse(JSON.stringify(company_list.data));
	        setTemplate(companiesList.data.lead_templates);
        }catch(e){
        	return false;
        }    
	}	

	/*------activate and deactivate--------*/

     const change_status_tags = async(index,status)=>{
        var id = index;
        if(!window.confirm('Are you sure want to change this')){
            return false;
        }else{
            try{
                setCheckAdd(false);
                if(status==1){
                    var updated_status=0;
                }else{
                    var updated_status=1;
                }
                var form = new FormData();
                form.append('status',updated_status);
                form.append('id',index);
                const activate_department = await axios.post(APP_URL+'update-lead-template-status',form,{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_change_status = JSON.parse(JSON.stringify(activate_department.data));
                if(js_data_change_status.status==1){
                    setCheckAdd(true);
                    toast.success('status has been changed');                    
                }else{
                    toast.error('something went wrong');
                }
            }catch(e){
                    toast.error('something went wrong');
            }
        }
    }

    /*------------delete lead template-------*/

     const delete_lead_template = async(index)=>{      
        let id=index;        
        if(!window.confirm('Are you sure want to delete this')){
            return false;
        }else{
            try{
                 setCheckAdd(false);
                const delete_company_details = await axios.get(APP_URL+'delete-lead-template?id='+`${id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
                var js_data_delete_company = JSON.parse(JSON.stringify(delete_company_details.data));
                if(js_data_delete_company.status==1){
                     setCheckAdd(true);
                    toast.success('template deleted successfull');                   
                }
            }catch(e){
                 toast.error('something went wrong');
            }
        }

    }



	return(
			<>
			 <div id="app">
			   <div id="main" className="layout-horizontal">
               <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
			      <ToastContainer/>
			      <div className="content-wrapper container">
			         <div className="page-content">
			            <section className="row">
			               <div className="col-12 col-lg-12">
			                  <section className="section">
			                     <div className="row" id="table-hover-row">
			                        <div className="col-12">
			                           <div className="card">
			                              <div className="card-header" style={{borderBottom:'1px solid'}}>
			                              <h5>Manage Template</h5>
			                           </div>
			                           <div className="card-body">			                           	
			                              <div className="row mt-2">
			                              	<div className="col-md-12 mb-3">
			                           		 	{
                                                  permissionlist && permissionlist.map((p,ind)=>(
                                                      <>
                                                      {
                                                        accesspermission.some(item1=>p.id==item1)==true?
                                                        <>  
                                                            {p.permission=='Activate'?<Link to={'/lead-template/add-template'}><button className="btn btn-success"><i className="fa fa-plus"></i> Add New Template</button></Link>:null}                                                        
                                                            
                                                    </>
                                                    :null
                                                    
                                                }
                                                </>
                                                    ))
                                                }
			                           		</div>
			                                 <div className="col-md-12">			                                    
			                                    <div className="table-responsive pb-5">
			                                       <table className="table table-bordered">
			                                          <thead>
			                                             <tr>
			                                                <th style={{width:"200px"}}>Title</th>
			                                                <th>Tags</th>
			                                                <th>Template Type</th>
			                                                <th>Status</th>
			                                                <th>Action</th>			                                                
			                                             </tr>
			                                          </thead>
			                                          <tbody>
			                                          {
			                                          	template && template.map((item)=>(
			                                          		<>
			                                          			<tr>
			                                          				<td>{item.title}</td>
			                                          				<td>{item.tags_details.tags}</td>
			                                          				<td>{item.template_type==1?'Mail':item.template_type==2?'WhatsApp':item.template_type==3?'SMS':null}</td>
			                                          				 <td>{item.status==1?<span className="badge bg-light-success">Activate</span>:<span className="badge bg-light-danger">Deactivate</span>}</td>
			                                          				<td>
			                                          				<div className="buttons">
                                                           
							                                                {
							                                                      permissionlist && permissionlist.map((p,ind)=>(
							                                                          <>
							                                                          {
							                                                            accesspermission.some(item1=>p.id==item1)==true?
							                                                            <>  
							                                                                {p.permission=='Activate'?<button className="btn btn-warning rounded-pill btn-custom" onClick={()=>change_status_tags(item.id,item.status)}><i className="fa fa-ban"></i></button>:null}                                                        
							                                                                {p.permission=='Edit'?<Link to={'/lead-template/add-template?temp_id='+`${item.id}`}><button className="btn btn-success rounded-pill btn-custom"><i className="fa fa-pencil"></i></button></Link>:null}
							                                                                {p.permission=='Delete'?<button className="btn btn-danger rounded-pill btn-custom" onClick={()=>delete_lead_template(item.id)}><i className="fa fa-trash"></i></button>:null}
							                                                        
							                                                        </>
							                                                        :null
							                                                        
							                                                    }
							                                                    </>
							                                                        ))
							                                                    }

							                                                
							                                            </div>
			                                          				</td>
			                                          			</tr>
			                                          		</>
			                                          	))
			                                          }
			                                          </tbody>
			                                       </table>
			                                    </div>
			                                 </div>
			                              </div>
			                           </div>
			                        </div>
			                     </div>
			               </div>
			               </section> 
			         </div>
			         </section>
			      </div>
			      <Footer/>
			   </div>
			</div>
			</div>
			</>
		);
}

export default MailTemplate;