import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {APP_URL,BASE_URL} from '../../../validation/basic';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Install=({step,nextStep,prevStep,departments,employee,setEmployees,connectcode,setConnectcode})=>{
	const [token,setToken]=useState(localStorage.getItem('token'));
	useEffect(()=>{
		setToken(localStorage.getItem('token'));
	},[])
	 const changedepartment =async(e)=>{
	 	let dept = e.target.value;
		try{
				const loademployee = await axios.get(APP_URL+'departments-user-list?department_id='+`${dept}`,{ headers: {"Authorization" : `Bearer ${token}`}});
				let employeelist = JSON.parse(JSON.stringify(loademployee.data));
				if(employeelist.status==1){
							setEmployees(employeelist.data.users);
				}
		}catch(e){
				return false;
		}
   }


   const changeemployee = (e)=>{
   		let connection_Code = e.target.value;
   		//console.log(connection_Code)
   		setConnectcode(connection_Code);

   }
 
 	
	return (
			<>
				<div className="row mt-5">
					<div className="col-lg-12" style={{textAlign:"center"}}>

							<div className="item-content mt-3">
								<p><strong>Select Employee and Install CorpBizz call App from given bellow App Link</strong></p>
							</div>

							<div className="row">
								<div className="col-md-4"></div>
								<div className="col-md-4">
									<div className="col-12 mb-4">
										<select className="form-control" onChange={(e)=>changedepartment(e)}>
											<option value="0">Select Department</option>
											{
												departments.map((item,index)=>(
												<>
													<option value={item.id}>{item.department_name}</option>
												</>
												))
											}
										</select>
									</div>
									<div className="col-12 mb-4">
										<select className="form-control" onChange={(e)=>changeemployee(e)}>
											<option value="0">Select Employee</option>
											{
												employee.map((item,index)=>(
														<option value={item.user_unique_code}>{item.name}</option>
													))
											}
										</select>
									</div>

									<div className="item-content-img">
										<a href={"https://cdn.closecall.in/app/close-call-recorder.apk"} target="_blank" downloads><img src={BASE_URL+"/assets/images/android_icon.png"} style={{width:'150px'}} /></a>
									</div>
								</div>
								<div className="col-md-4"></div>
								
							</div>
								

					</div>
				</div>
			</>
		)
}


export default Install;