import React,{useState  ,useEffect}  from 'react';
import {Link,useLocation} from 'react-router-dom';
import Topbar from '../../layout/topbar';
import Footer from '../../layout/footer';
import axios from 'axios';
import {APP_URL,getdates} from '../../validation/basic';
import {ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import Paginationcustom from '../../validation/pagination';
import Select from 'react-select';
import Loader from '../../loader/loader';
import AddLead from './AddLead';
const FollowUp=()=>{
   const {search}=useLocation();
	const [permissionlist,setPermissionlist]=useState([]);
   const [accesspermission,setAccesspermission]=useState([]);
   const [menuids,setMenuids]=useState(''); 
   const [token,setToken]=useState(localStorage.getItem('token'));
   const LeadID=new URLSearchParams(search).get("leadID");
   const [lead_id,setLead_id]=useState(LeadID!='' || LeadID!=null?LeadID:'');
   const [leadFollowInbox,setLeadFollowInbox]=useState(null);
   const [leadPowerList,setLeadPowerList]=useState([]);
   const [isUpdateLead,setIsUpdateLead]=useState(false);

   const [isSendIDError,setIsSendIDError]=useState(true);
   const [isRecieveIDError,setIsRecieveIDError]=useState(true);
   const [isSubjectError,setIsSubjectError]=useState(true);
   const [isTemplateIDError,setIsTemplateError]=useState(true);
   const [isFollowTypeError,setIsFollowTypeError]=useState(true);
   const [isFollowDateError,setIsFollowDateError]=useState(true);
   const [isMessageError,setIsMessageError]=useState(true);

   const [isWSendIDError,setIsWSendIDError]=useState(true);
   const [isWRecieveIDError,setIsWRecieveIDError]=useState(true);
   const [isWSubjectError,setIsWSubjectError]=useState(true);
   const [isWTemplateIDError,setIsWTemplateError]=useState(true);
   const [isWFollowTypeError,setIsWFollowTypeError]=useState(true);
   const [isWFollowDateError,setIsWFollowDateError]=useState(true);
   const [isWMessageError,setIsWMessageError]=useState(true);

   const [isSSendIDError,setIsSSendIDError]=useState(true);
   const [isSRecieveIDError,setIsSRecieveIDError]=useState(true);
   const [isSSubjectError,setIsSSubjectError]=useState(true);
   const [isSTemplateIDError,setIsSTemplateError]=useState(true);
   const [isSFollowTypeError,setIsSFollowTypeError]=useState(true);
   const [isSFollowDateError,setIsSFollowDateError]=useState(true);
   const [isSMessageError,setIsSMessageError]=useState(true);

   const [isNSendIDError,setIsNSendIDError]=useState(true);
   const [isNRecieveIDError,setIsNRecieveIDError]=useState(true);
   const [isNSubjectError,setIsNSubjectError]=useState(true);
   const [isNTemplateIDError,setIsNTemplateError]=useState(true);
   const [isNFollowTypeError,setIsNFollowTypeError]=useState(true);
   const [isNFollowDateError,setIsNFollowDateError]=useState(true);
   const [isNMessageError,setIsNMessageError]=useState(true);

   const [mailTemplateList,setMailTemplateList]=useState([]);
   const [whTemplateList,setWhTemplateList]=useState([]);
   const [smsTemplateList,setSmsTemplateList]=useState([]);

   const [isFollowDescError,setIsFollowDescError]=useState(true);
   const [isFollowUpDateError,setIsFollowUpDateError]=useState(true);

   const [composeMail,setComposeMail]=useState({
      send_id:'',
      recieve_id:'',
      subject:'',
      template_id:0,
      follow_up_date:'',
      message:'',
      follow_type:0,
      agent_id:'',
      agent_name:'',
      agent_email:'',
      agent_mobile:''
   });
   const [composeWhatsApp,setComposeWhatsApp]=useState({
      wsend_id:'',
      wrecieve_id:'',
      wsubject:'',
      wtemplate_id:0,
      wfollow_up_date:'',
      wmessage:'',
      wfollow_type:0,
      wagent_id:'',
      wagent_name:'',
      wagent_email:'',
      wagent_mobile:''
   });
   const [composeSMS,setComposeSMS]=useState({
      ssend_id:'',
      srecieve_id:'',
      ssubject:'',
      stemplate_id:0,
      sfollow_up_date:'',
      smessage:'',
      sfollow_type:0,
      sagent_id:'',
      sagent_name:'',
      sagent_email:'',
      sagent_mobile:''
   });
   const [composeNote,setComposeNote]=useState({
      nsend_id:'',
      nrecieve_id:'',
      nsubject:'',
      ntemplate_id:0,
      nfollow_up_date:'',
      nmessage:'',
      nfollow_type:0,
      nagent_id:'',
      nagent_name:'',
      nagent_email:'',
      nagent_mobile:''
   });
   const {send_id,recieve_id,subject,template_id,follow_up_date,message,follow_type,agent_id,agent_name,agent_email,agent_mobile}=composeMail;
   const {wsend_id,wrecieve_id,wsubject,wtemplate_id,wfollow_up_date,wmessage,wfollow_type,wagent_id,wagent_name,wagent_email,wagent_mobile}=composeWhatsApp;
   const {ssend_id,srecieve_id,ssubject,stemplate_id,sfollow_up_date,smessage,sfollow_type,sagent_id,sagent_name,sagent_email,sagent_mobile}=composeSMS;
   const {nsend_id,nrecieve_id,nsubject,ntemplate_id,nfollow_up_date,nmessage,nfollow_type,nagent_id,nagent_name,nagent_email,nagent_mobile}=composeNote;

   /*------add follow up data to lead---*/
   const [addFollowUpData,setAddFollowUpData]=useState({
      follow_desc:'',
      followup_date:''
   });

   const {follow_desc,followup_date}=addFollowUpData;
   
   /*-------contact details of lead------*/
   const [contactData,setContactData]=useState({
      contact_name:'',
      contact_mobile:'',
      contact_email:''
   });

   const {contact_name,contact_mobile,contact_email}=contactData;

   useEffect(()=>{
      setToken(localStorage.getItem('token'));
      LeadFollowDetails();
      loadleadPower();     
   },[isUpdateLead]);

     /*--------task toggle open and hide------*/
     const TaskToggle=()=>{
         let ele = document.querySelector('.custom_task');
         if(ele.style.display=='none'){
            ele.style.display='block';
         }else{
            ele.style.display='none';
         }
     }   

     /*---------contact details open and hide----------*/ 
      
      const ContactToggle=()=>{
         let ele = document.querySelector('.custom_contact_toggle');
         if(ele.style.display=='none'){
            ele.style.display='block';
         }else{
            ele.style.display='none';
         }
     } 

     /*-------contact details hide and show--------*/
   
     const ContactDetailsToggle=()=>{
         let ele = document.querySelector('.contact_details_toggle');
         if(ele.style.display=='none'){
            ele.style.display='block';
         }else{
            ele.style.display='none';
         }
     } 

     /*--------mail open and hide ----------*/     
      const MailDetailsToggle=(index)=>{
         let ele = document.querySelector('.mail_inner_details_'+index);
         if(ele.style.display=='none'){
            ele.style.display='block';
         }else{
            ele.style.display='none';
         }
     } 

     /*----------compose mail hide and show--------*/

     const ComposeMailToggle=()=>{
         let ele = document.querySelector('.send_mail_custom_open');
         if(ele.style.display=='none'){
            ele.style.display='block';
            loadTemplateDetails(1)
         }else{
            ele.style.display='none';
         }
     } 

     /*-------send message hide and show-------*/

      const SendMSGToggle=()=>{
         let ele = document.querySelector('.send_msg_details');
         if(ele.style.display=='none'){
            ele.style.display='block';
            loadTemplateDetails(3)
         }else{
            ele.style.display='none';
         }
     } 
     /*---send whatsapp hide and show-------*/
      
     const SendWhatsAppToggle=()=>{
         let ele = document.querySelector('.send_whatsapp_details');
         if(ele.style.display=='none'){
            ele.style.display='block';
            loadTemplateDetails(2)
         }else{
            ele.style.display='none';
         }
     } 

     /*-----send note details hide and show-------*/
     
     const SendNoteToggle=()=>{
         let ele = document.querySelector('.send_note_details');
         if(ele.style.display=='none'){
            ele.style.display='block';
         }else{
            ele.style.display='none';
         }
     }

     /*------------lead follow up details---------*/

     const LeadFollowDetails=async()=>{
         try{
            const lead_follow_up_details = await axios.get(APP_URL+'view-follow-lead-details?lead_id='+`${lead_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
            let follow_details = JSON.parse(JSON.stringify(lead_follow_up_details.data));
            if(follow_details.status==1){
               setLeadFollowInbox(follow_details.data.lead_list);
               setComposeMail({...composeMail,send_id:follow_details.data.lead_list.lead_assign_user.email,
                  recieve_id:follow_details.data.lead_list.email_id,
                  agent_id:follow_details.data.lead_list.assing_id,
                  agent_name:follow_details.data.lead_list.lead_assign_user.name,
                  agent_email:follow_details.data.lead_list.lead_assign_user.email,
                  agent_mobile:follow_details.data.lead_list.lead_assign_user.mobile,

               });
               setComposeWhatsApp({...composeWhatsApp,wsend_id:follow_details.data.lead_list.lead_assign_user.mobile,
                  wrecieve_id:follow_details.data.lead_list.mobile,
                  wagent_id:follow_details.data.lead_list.assing_id,
                  wagent_name:follow_details.data.lead_list.lead_assign_user.name,
                  wagent_email:follow_details.data.lead_list.lead_assign_user.email,
                  wagent_mobile:follow_details.data.lead_list.lead_assign_user.mobile,

               });
               setComposeSMS({...composeSMS,ssend_id:follow_details.data.lead_list.lead_assign_user.mobile,
                  srecieve_id:follow_details.data.lead_list.mobile,
                  sagent_id:follow_details.data.lead_list.assing_id,
                  sagent_name:follow_details.data.lead_list.lead_assign_user.name,
                  sagent_email:follow_details.data.lead_list.lead_assign_user.email,
                  sagent_mobile:follow_details.data.lead_list.lead_assign_user.mobile,
               });
               setComposeNote({...composeNote,nsend_id:follow_details.data.lead_list.lead_assign_user.mobile,
                  nrecieve_id:follow_details.data.lead_list.mobile,
                  nagent_id:follow_details.data.lead_list.assing_id,
                  nagent_name:follow_details.data.lead_list.lead_assign_user.name,
                  nagent_email:follow_details.data.lead_list.lead_assign_user.email,
                  nagent_mobile:follow_details.data.lead_list.lead_assign_user.mobile,
               });

               setContactData({...contactData,contact_name:follow_details.data.lead_list.name,
                  contact_email:follow_details.data.lead_list.email_id,
                  contact_mobile:follow_details.data.lead_list.prefix_mob+follow_details.data.lead_list.mobile
               })
            }
         }catch(e){
            return false;
         }
     } 

     /*------list active lead power---------*/

const loadleadPower = async()=>{
   try{
      const users = await axios.get(APP_URL+'list-active-lead-power',{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setLeadPowerList(users_list_data.data.lead_tags);
   }catch(e){
      return null;
   }
} 

/*-----------lead status change activity---------*/

const leadStatusChange=async(e)=>{
   try{
       setIsUpdateLead(false);
       let form = new FormData();
       form.append('lead_id',lead_id);
       form.append('lead_power',e.target.value);
       const update_lead_status = await axios.post(APP_URL+'lead-status-change-activity',form,{ headers: {"Authorization" : `Bearer ${token}`}});
       let update_lead_status_cnf = JSON.parse(JSON.stringify(update_lead_status.data));
       if(update_lead_status_cnf.status==1){
         setIsUpdateLead(true);
         toast.success('lead status has been changed');
       }

   }catch(e){
      toast.error('something went wrong');
      return false;
   }
}

/*---------handle compose mail input change-------*/

const handleMailTo=(e)=>{
   setComposeMail({...composeMail,recieve_id:e.target.value});
}

const handleMailSubject=(e)=>{
   setComposeMail({...composeMail,subject:e.target.value});
}

const handleTemplate=async(e)=>{    
   try{
      let temp_id = e.target.value;      
      const template_data = await axios.get(APP_URL+'lead-template-data-change?lead_id='+`${lead_id}`+'&temp_id='+`${temp_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      const template_data_change = JSON.parse(JSON.stringify(template_data.data));
      if(template_data_change.status==1){
         setComposeMail({...composeMail,subject:template_data_change.data.lead_templates.subject,
            message:template_data_change.data.lead_templates.updated_content,
            template_id:temp_id
         });

      }      
   }catch(event){
      return false;
   }   
}

const handleFollowDate=(e)=>{
   setComposeMail({...composeMail,follow_up_date:e.target.value});
}

const handleMailMessage=(e)=>{
   setComposeMail({...composeMail,message:e.target.value});
}

/*-------handle compose whatsApp input change---------*/

const handleWhatsAppTo=(e)=>{
   setComposeMail({...composeWhatsApp,wrecieve_id:e.target.value});
}

const handleWhatsAppSubject=(e)=>{
   setComposeWhatsApp({...composeWhatsApp,wsubject:e.target.value});
}

const handleWhatsAppTemplate=async(e)=>{
    try{
      let temp_id = e.target.value;      
      const template_data = await axios.get(APP_URL+'lead-template-data-change?lead_id='+`${lead_id}`+'&temp_id='+`${temp_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      const template_data_change = JSON.parse(JSON.stringify(template_data.data));
      if(template_data_change.status==1){
         setComposeWhatsApp({...composeWhatsApp,wsubject:template_data_change.data.lead_templates.subject,
            wmessage:template_data_change.data.lead_templates.updated_content,
            wtemplate_id:temp_id
         });

      }      
   }catch(event){
      return false;
   } 
}

const handleWhatsAppFollowDate=(e)=>{
   setComposeWhatsApp({...composeWhatsApp,wfollow_up_date:e.target.value});
}

const handleWhatsAppMessage=(e)=>{
   setComposeWhatsApp({...composeWhatsApp,wmessage:e.target.value});
}

/*----------handle compose sms input change-----------*/

const handleSMSTo=(e)=>{
   setComposeSMS({...composeSMS,srecieve_id:e.target.value});
}

const handleSMSSubject=(e)=>{
   setComposeSMS({...composeSMS,ssubject:e.target.value});
}

const handleSMSTemplate=async(e)=>{
    try{
      let temp_id = e.target.value;      
      const template_data = await axios.get(APP_URL+'lead-template-data-change?lead_id='+`${lead_id}`+'&temp_id='+`${temp_id}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      const template_data_change = JSON.parse(JSON.stringify(template_data.data));
      if(template_data_change.status==1){
         setComposeSMS({...composeSMS,ssubject:template_data_change.data.lead_templates.subject,
            smessage:template_data_change.data.lead_templates.updated_content,
            stemplate_id:temp_id
         });

      }      
   }catch(event){
      return false;
   } 
}

const handleSMSMessage=(e)=>{
   setComposeSMS({...composeSMS,smessage:e.target.value});
}

/*-----------handle add lead note-------*/

const handleNoteMessage=(e)=>{
   setComposeNote({...composeNote,nmessage:e.target.value});
}

/*-----------validate mail input fields--------*/

const ValidateMailFields=()=>{
   if(recieve_id==''){
      setIsRecieveIDError(false);
   }else{
      setIsRecieveIDError(true);
   }
   if(subject==''){
      setIsSubjectError(false);
   }else{
      setIsSubjectError(true);
   }
   if(template_id==0){
      setIsTemplateError(false);
   }else{
      setIsTemplateError(true);
   }
   if(follow_up_date==''){
      setIsFollowDateError(false);
   }else{
      setIsFollowDateError(true);
   }
   if(message==''){
      setIsMessageError(false);
   }else{
      setIsMessageError(true);
   }
   if(recieve_id=='' || subject=='' || template_id==0 || follow_up_date=='' || message==''){
      return false;
   }else{
      return true;
   }
}

/*----------compose mail----------*/

const MailSend=async()=>{
   console.log(composeMail);
   if(ValidateMailFields()==true){
      try{
         setIsUpdateLead(false);
         let form = new FormData();
         form.append('lead_id',lead_id);
         form.append('agent_id',agent_id);
         form.append('agent_name',agent_name);
         form.append('agent_email',agent_email);
         form.append('agent_mobile',agent_mobile);
         form.append('send_id',send_id);
         form.append('recieve_id',recieve_id);
         form.append('subject',subject);
         form.append('message',message);
         form.append('followup_date',follow_up_date);
         const send_mail = await axios.post(APP_URL+'compose-mail',form,{ headers: {"Authorization" : `Bearer ${token}`}});
         const send_mail_data = JSON.parse(JSON.stringify(send_mail.data));
         if(send_mail_data.status==1){
            setIsUpdateLead(true);
            document.querySelector('.send_mail_custom_open').style.display='none';
            toast.success('mail sent successfull');
         }

      }catch(e){
         toast.error('something went wrong');
         return false;
      }
   }else{
      return false;
   }
}

/*------------validate whatsapp field----------*/

const ValidateFieldsWhatsApp=()=>{
   if(wrecieve_id==''){
      setIsWRecieveIDError(false);
   }else{
      setIsWRecieveIDError(true);
   }
   if(wsubject==''){
      setIsWSubjectError(false);
   }else{
      setIsWSubjectError(true);
   }
   if(wtemplate_id==0){
      setIsWTemplateError(false);
   }else{
      setIsWTemplateError(true);
   }
   if(wfollow_up_date==''){
      setIsWFollowDateError(false);
   }else{
      setIsWFollowDateError(true);
   }
   if(wmessage==''){
      setIsWMessageError(false);
   }else{
      setIsWMessageError(true);
   }
   if(wrecieve_id=='' || wsubject=='' || wtemplate_id==0 || wfollow_up_date=='' || wmessage==''){
      return false;
   }else{
      return true;
   }

}

/*--------------send whatsapp----------*/

const composeWhatsAppSend=async()=>{
   if(ValidateFieldsWhatsApp()==true){
      try{
         setIsUpdateLead(false);
         let form = new FormData();
         form.append('lead_id',lead_id);
         form.append('agent_id',wagent_id);
         form.append('agent_name',wagent_name);
         form.append('agent_email',wagent_email);
         form.append('agent_mobile',wagent_mobile);
         form.append('send_id',wsend_id);
         form.append('recieve_id',wrecieve_id);
         form.append('subject',wsubject);
         form.append('message',wmessage);
         form.append('followup_date',wfollow_up_date);
         const send_mail = await axios.post(APP_URL+'compose-whatsApp',form,{ headers: {"Authorization" : `Bearer ${token}`}});
         const send_mail_data = JSON.parse(JSON.stringify(send_mail.data));
         if(send_mail_data.status==1){
            setIsUpdateLead(true);
            document.querySelector('.send_whatsapp_details').style.display="none";
            toast.success('sent message successfull');
         }

      }catch(e){
         toast.error('something went wrong');
         return false;
      }
   }else{
      return false;
   }
}


/*-----------validate sms----------*/

const ValidateFieldsSMS=()=>{
   if(srecieve_id==''){
      setIsSRecieveIDError(false);
   }else{
      setIsSRecieveIDError(true);
   }
   if(ssubject==''){
      setIsSSubjectError(false);
   }else{
      setIsSSubjectError(true);
   }
   if(stemplate_id==0){
      setIsSTemplateError(false);
   }else{
      setIsSTemplateError(true);
   }
   
   if(smessage==''){
      setIsSMessageError(false);
   }else{
      setIsSMessageError(true);
   }
   if(srecieve_id=='' || ssubject=='' || stemplate_id==0 || smessage==''){
      return false;
   }else{
      return true;
   }

}

/*------------sent sms-----------*/

const composeSMSSend=async()=>{
   if(ValidateFieldsSMS()==true){
      try{
         setIsUpdateLead(false);
         let form = new FormData();
         form.append('lead_id',lead_id);
         form.append('agent_id',sagent_id);
         form.append('agent_name',sagent_name);
         form.append('agent_email',sagent_email);
         form.append('agent_mobile',sagent_mobile);
         form.append('send_id',ssend_id);
         form.append('recieve_id',srecieve_id);
         form.append('subject',ssubject);
         form.append('message',smessage);        
         const send_mail = await axios.post(APP_URL+'compose-sms',form,{ headers: {"Authorization" : `Bearer ${token}`}});
         const send_mail_data = JSON.parse(JSON.stringify(send_mail.data));
         if(send_mail_data.status==1){
            setIsUpdateLead(true);
            document.querySelector('.send_msg_details').style.display="none";
            toast.success('sent message successfull');
         }
      }catch(e){
         toast.error('something went wrong');
         return false;
      }
   }else{
      return false;
   }
}

/*-----------validate lead note fields----------*/
const ValidateFieldsNote=()=>{   
   if(nmessage==''){
      setIsNMessageError(false);
   }else{
      setIsNMessageError(true);
   }
   if(nmessage==''){
      return false;
   }else{
      return true;
   }

}

/*-----------add lead note---------*/

const AddLeadNote=async()=>{
   if(ValidateFieldsNote()==true){
      try{
         setIsUpdateLead(false);
         let form = new FormData();
         form.append('lead_id',lead_id);
         form.append('agent_id',nagent_id);
         form.append('agent_name',nagent_name);
         form.append('agent_email',nagent_email);
         form.append('agent_mobile',nagent_mobile);         
         form.append('message',nmessage);        
         const send_mail = await axios.post(APP_URL+'add-lead-note',form,{ headers: {"Authorization" : `Bearer ${token}`}});
         const send_mail_data = JSON.parse(JSON.stringify(send_mail.data));
         if(send_mail_data.status==1){
            setIsUpdateLead(true);
            document.querySelector('.send_note_details').style.display="none";
            toast.success('Note added successfull');
         }
      }catch(e){
         toast.error('something went wrong');
         return false;
      }
   }else{
      return false;
   }
}

/*-----------load template details----------*/

const loadTemplateDetails = async(tpid)=>{
   try{      
      const users = await axios.get(APP_URL+'activate-lead-template-list?template_type='+`${tpid}`,{ headers: {"Authorization" : `Bearer ${token}`}});
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      if(tpid==1){
          setMailTemplateList(users_list_data.data.lead_templates);
       }else if(tpid==2){
          setWhTemplateList(users_list_data.data.lead_templates);
       }else if(tpid==3){
          setSmsTemplateList(users_list_data.data.lead_templates);
       }
     
   }catch(e){
      return null;
   }
} 

/*---------------handle add follow up----------*/

const handleAddFollowUpChange=(e)=>{
   setAddFollowUpData({...addFollowUpData,[e.target.name]:e.target.value});
}

/*------------validate follow up fields-------*/

const ValidateFieldsFollowNote=()=>{   
   if(follow_desc==''){
      setIsFollowDescError(false);
   }else{
      setIsFollowDescError(true);
   }
   if(followup_date==''){
      setIsFollowUpDateError(false);
   }else{
      setIsFollowUpDateError(true);
   }
   if(follow_desc=='' || followup_date==''){
      return false;
   }else{
      return true;
   }

}

/*-------------add follow up submit--------*/

const AddFollowUpNote=async()=>{
   if(ValidateFieldsFollowNote()==true){
      try{
         setIsUpdateLead(false);
         let form = new FormData();
         form.append('lead_id',lead_id);
         form.append('agent_id',nagent_id);
         form.append('agent_name',nagent_name);
         form.append('agent_email',nagent_email);
         form.append('agent_mobile',nagent_mobile);         
         form.append('message',follow_desc); 
         form.append('followup_date',followup_date);       
         const send_mail = await axios.post(APP_URL+'add-lead-note',form,{ headers: {"Authorization" : `Bearer ${token}`}});
         const send_mail_data = JSON.parse(JSON.stringify(send_mail.data));
         if(send_mail_data.status==1){
            setIsUpdateLead(true);            
            toast.success('Follow Up add successfull');
         }
      }catch(e){
         toast.error('something went wrong');
         return false;
      }
   }else{
      return false;
   }
}

	return (
		<>
<div id="app">
   <div id="main" className="layout-horizontal">
      <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
      <ToastContainer />
      <div className="content-wrapper container">
         <div className="page-content">
            <div className="row">
               <div className="col-12">
                  <div className="card">
                     <div className="card-header" style={{borderBottom:'1px solid'}}>
                     <h5>Lead Follow Up</h5>
                  </div>
                  <div className="card-body">
                     <div className="">
                        <div className="row">
                           <div className="col-sm-12">
                              <section className="panel">
                                 <div className="panel-inner">
                                    <div className="panel-body">
                                       <div className="row">
                                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                             <div className="page-heading">
                                                <h3>{leadFollowInbox && leadFollowInbox.name}</h3>
                                                <p className="mb-0"><span className="number-inbox-leads"><small>{leadFollowInbox && leadFollowInbox.service_tag.tags}</small></span>
                                                </p>
                                                <p className="mb-0">{leadFollowInbox && leadFollowInbox.email_id}
                                                </p>
                                                <p className="mb-0">{leadFollowInbox && leadFollowInbox.prefix_mob+leadFollowInbox.mobile}                                                </p>
                                             </div>
                                             <div className="task-wrapper">
                                                <select className="custom-select form-dropdown" onChange={(e)=>leadStatusChange(e)}>
                                                   <option value="0" selected="" disabled="">
                                                      Lead Status
                                                   </option>
                                                   {
                                                      leadPowerList && leadPowerList.map((item)=>(
                                                         <>
                                                            {
                                                               leadFollowInbox && leadFollowInbox.lead_power==item.id?<>
                                                                 <option value={item.id} selected>{item.power_name}</option>
                                                            </>
                                                            :
                                                            <>  <option value={item.id}>{item.power_name}</option></>
                                                         }
                                                         </>                                                        
                                                      ))
                                                   }
                                                  
                                                </select>
                                                <div className="task-wrapper-list mb-3">
                                                   <div className="row-d-flex task-col">
                                                      <div className="col-md-10">
                                                         <p className="pol5">Add Follow Up</p>
                                                      </div>
                                                      <div className="col-md-2">
                                                         <button type="button" className="add-task add-task1" onClick={TaskToggle}><i className="fa fa-plus"></i></button>
                                                      </div>
                                                   </div>
                                                   <div className="col-sm-12 add-task-form add-task-form1 custom_task" style={{display:'none'}}>
                                                      <div className="row">                                                        
                                                            <div className="col-sm-12 mbottom5">
                                                               <div className="row">
                                                                  <label className="control-label">Descripition</label>
                                                                  <input type="text" name="follow_desc" className={"form-control"+(isFollowDescError==false?' invalid':'')} placeholder="Add Note" onChange={(e)=>handleAddFollowUpChange(e)}/>
                                                               </div>
                                                            </div>
                                                            <div className="mbottom5 p-0">
                                                               <div className="row">
                                                                  <div className="col-sm-12">
                                                                     <label className="control-label">Date</label>
                                                                     <input type="date" name="followup_date" className={"form-control"+(isFollowUpDateError==false?' invalid':'')} onChange={(e)=>handleAddFollowUpChange(e)}/>
                                                                  </div>                                                                 
                                                               </div>
                                                            </div>
                                                           
                                                            <div className="col-sm-12 mtop10">
                                                               <div className="d-flex align-items-center justify-content-end">
                                                                  <button type="button" className="btn-cancel-task btn-cancel-task1" onClick={TaskToggle}>Cancel</button>
                                                                  <button type="button" className="btn-save-task" onClick={AddFollowUpNote}>Save</button>
                                                               </div>
                                                            </div>                                                       
                                                      </div>
                                                   </div>
                                                   <div className="row-d-flex show-edit-btn bg-white">
                                                      <div className="col-md-12 d-flex">
                                                         <div className="user-task-assigned">
                                                            {leadFollowInbox && leadFollowInbox.message!=null?leadFollowInbox.message.charAt(0).toUpperCase():null}
                                                         </div>
                                                         <div className="task-type">
                                                            <p className="task-type1">{leadFollowInbox && leadFollowInbox.message}</p>
                                                            <p className="task-date1">{leadFollowInbox && leadFollowInbox.follow_up_date}</p>
                                                         </div>
                                                      </div>
                                                     {/* <div className="col-md-4 mt-7">
                                                           <div className="btn-group btn-action">
                                                              <button className="edit-task-btn" type="button" data-placement="bottom" data-toggle="tooltip" title="Edit Task"><i className="fa fa-pencil"></i></button>
                                                              <button type="button" data-placement="bottom" data-toggle="tooltip" title="Snooze this Task to appear in your Inbox at a later date"><i className="fa fa-bell"></i></button>
                                                              <button type="button" data-placement="bottom" data-toggle="tooltip" title="Mark this task as done"><i className="fa fa-check"></i></button>
                                                           </div>
                                                        </div> */}
                                                   </div>
                                                </div>
                                               
                                                <div className="task-wrapper-list mt-1">
                                                   <div className="row-d-flex task-col">
                                                      <div className="col-md-10">
                                                         <p className="pol5">CONTACTS</p>
                                                      </div>
                                                      <div className="col-md-2">
                                                         <button type="button" className="add-task add-task3" onClick={ContactToggle}><i className="fa fa-plus"></i></button>
                                                      </div>
                                                   </div>
                                                   <div className="col-sm-12 add-task-form add-task-form3 custom_contact_toggle" style={{display:'none'}}>
                                                      <div className="row">                                                     
                                                            <div className="col-sm-12 mbottom5">
                                                               <div className="row">
                                                                  <label className="control-label">Name</label>
                                                                  <input type="text" className="form-control" placeholder="Name" value={contact_name}/>
                                                               </div>
                                                            </div>
                                                            <div className="col-sm-12 mbottom5">
                                                               <div className="row">
                                                                  <label className="control-label">Mobile</label>
                                                                  <input type="text" className="form-control" placeholder="Mobile" value={contact_mobile}/>
                                                               </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                               <div className="row">
                                                                  <label className="control-label">Email</label>
                                                                  <input type="text" className="form-control" placeholder="Email" value={contact_email}/>
                                                               </div>
                                                            </div>
                                                            <div className="col-sm-12 mtop10">
                                                               <div className="d-flex align-items-center justify-content-end">
                                                                  <button type="button" className="btn-cancel-task btn-cancel-task3" onClick={ContactToggle}>Cancel</button>
                                                                  <button type="button" className="btn-save-task">Save</button>
                                                               </div>
                                                            </div>                                                         
                                                      </div>
                                                   </div>
                                                   <div className="row-d-flex show-edit-btn bg-white">
                                                      <div className="col-md-12 row-d-flex" onClick={ContactDetailsToggle}>
                                                         <div className="task-type show-contact-details">
                                                            <p className="task-type1">{leadFollowInbox && leadFollowInbox.name}</p>
                                                            <p className="task-date1">{leadFollowInbox && leadFollowInbox.service_tag.tags}</p>
                                                         </div>
                                                      </div>
                                                      {/*<div className="col-md-4 mt-7">
                                                                                                               <div className="btn-group btn-action">
                                                                                                                  <button type="button" data-placement="bottom" data-toggle="tooltip" title="Email:taruns715@gmail.com"><i className="fa fa-envelope"></i></button>
                                                                                                                  <button type="button" data-placement="bottom" data-toggle="tooltip" title="Edit"><i className="fa fa-edit"></i></button>
                                                                                                                  <button type="button" data-placement="bottom" data-toggle="tooltip" title="Delete"><i className="fa fa-trash-o"></i></button>
                                                                                                               </div>
                                                                                                            </div>*/}
                                                   </div>
                                                   <div className="contact-details-user contact_details_toggle" style={{display:'none'}}>
                                                      <div className="d-flex align-items-center justify-content-between">
                                                         <div>
                                                            <p className="mb-0">Office</p>
                                                         </div>
                                                         <div>
                                                            <a href={"callto:"+(leadFollowInbox && leadFollowInbox.prefix_mob+leadFollowInbox.mobile)}>{leadFollowInbox && leadFollowInbox.prefix_mob+leadFollowInbox.mobile}</a>
                                                         </div>
                                                      </div>
                                                      <div className="d-flex justify-content-between align-items-center mtop10">
                                                         <div>
                                                            <p className="mb-0">Office</p>
                                                         </div>
                                                         <div>
                                                            <a href={"mailto:"+(leadFollowInbox && leadFollowInbox.email_id)}>{leadFollowInbox && leadFollowInbox.email_id}</a>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                               {/* <!-- Merge and Delete lead -->*/}
                                                <div className="task-wrapper-list mt-3">
                                                   <div className="row-d-flex merge-delete-btn">
                                                      <div className="col">
                                                         <button type="button"><i className="fa fa-trash-o"></i>Delete Lead</button>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                                             <div className="row-d-flex">
                                                <div className="col">
                                                   <button type="button" className="btn btn-sm bradius" id="btn-add-email" onClick={ComposeMailToggle}><i className="fa fa-envelope"></i>&nbsp; Compose Mail</button>
                                                   <button type="button" className="btn btn-sm bradius" id="btn-add-note" onClick={SendWhatsAppToggle}><i className="fa fa-comment"></i>&nbsp; Whatsapp MSG</button>
                                                   <button type="button" className="btn btn-sm bradius" id="btn-add-sms" onClick={SendMSGToggle}><i className="fa fa-comments"></i>&nbsp; Compose SMS</button>
                                                   <button type="button" className="btn btn-sm bradius" id="btn-phone"><i className="fa fa-phone"></i>&nbsp; Call</button>
                                                   <button type="button" className="btn btn-sm bradius" id="btn-add-writer-notes" onClick={SendNoteToggle}><i className="fa fa-file-text"></i>&nbsp; Writer Note</button>
                                                </div>
                                             </div>
                                             <div className="row-d-flex spacing-lead mt-1 border-bottom1">
                                                <div className="col">
                                                   <div className="dropdown d-inline-block">
                                                      <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"><i className="fa fa-flag"></i>&nbsp; All Activities
                                                      <span className="caret"></span></button>
                                                      <div className="dropdown-menu pading1">
                                                         <input type="checkbox" className="mr-2" /><span>Select All</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="listing-lead-progress mtop40">
                                                <div className="row1"> 
                                                <div className='lead-progress testingmail send_mail_custom_open' style={{'display':'none'}}>
                                                      <div className="lead-in-progress-icon lead-status-mail">
                                                         <i className="fa fa-envelope"></i>
                                                      </div>
                                                      <section className="mail-box-info">                                                        
                                                            <section className="mail-list mt-0">
                                                               <div className="compose-mail">
                                                                  <div className="form-group">
                                                                     <label for="to" className="">To:</label>
                                                                     <input type="text" value={recieve_id} className={"form-control"+(isRecieveIDError==false?' invalid':'')} onChange={(e)=>handleMailTo(e)}/>                                                                     
                                                                  </div>
                                                                  
                                                                  <div className="form-group">
                                                                     <label for="subject" className="">Subject:</label>
                                                                     <input type="text" id="subject" name="subject" className={"form-control"+(isSubjectError==false?' invalid':'')} value={subject} onChange={(e)=>handleMailSubject(e)} />
                                                                  </div>
                                                                  <div className="form-group">
                                                                     <select className={"form-control"+(isTemplateIDError==false?' invalid':'')} name="template_id" id="template_id" onChange={(e)=>handleTemplate(e)}>
                                                                        <option value="0">Select Email Template</option>
                                                                        {
                                                                           mailTemplateList && mailTemplateList.map((item)=>(
                                                                              <>
                                                                                 <option value={item.id}>{item.title}</option>
                                                                              </>
                                                                              ))
                                                                        }
                                                                     </select>
                                                                  </div>
                                                                  <div className="form-group" style={{'marginTop':'10px'}}>
                                                                     <label for="subject">Follow Up:</label>
                                                                     <div className="col-md-6" style={{'paddingLeft':'0px'}}>
                                                                        <input size="16" type="date" name="followup" className={"form-control"+(isFollowDateError==false?' invalid':'')} value={follow_up_date} onChange={(e)=>handleFollowDate(e)} style={{'borderRadius':'0px','paddingLeft':'10px','background':'#fff'}} />
                                                                     </div>
                                                                  </div>
                                                                  <div className="compose-editor mt-1">
                                                                     <textarea className={"wysihtml5 form-control"+(isMessageError==false?' invalid':'')} name="message" id="message" rows="3" onChange={(e)=>handleMailMessage(e)} value={message}></textarea>
                                                                  </div>
                                                                  <hr/>
                                                                  <div className="compose-btn">
                                                                     <button className="btn btn-primary btn-sm" type="submit" name="submit" value="submit" onClick={MailSend}>Send</button>
                                                                  </div>
                                                               </div>
                                                            </section>                                                         
                                                         
                                                      </section>
                                                   </div> 
                                                    <div className="lead-progress send_whatsapp_details" style={{'display':'none'}}>
                                                   <div className="lead-in-progress-icon lead-status-comments">
                                                      <i className="fa fa-comments"></i>
                                                   </div>
                                                   <section className="mail-box-info">                                                      
                                                         <section className="mail-list mt-0">
                                                            <div className="compose-mail">
                                                               <div className="form-group">
                                                                  <label for="to" className="">Mobile:</label>
                                                                  <input type="tel" id="lead_phone" name="lead_phone" value={wrecieve_id} className={"form-control"+(isWRecieveIDError==false?' invalid':'')} onChange={(e)=>handleWhatsAppTo(e)}/>
                                                               </div>
                                                               <div className="form-group">
                                                                  <label for="subject" className="">Subject:</label>
                                                                  <input type="text" id="subject" name="subject" className={"form-control"+(isWSubjectError==false?' invalid':'')} value={wsubject} onChange={(e)=>handleWhatsAppSubject(e)}/>
                                                               </div>
                                                                <div className="form-group">                                                                  
                                                                  <select type="text" className={"form-control"+(isWTemplateIDError==false?' invalid':'')} onChange={(e)=>handleWhatsAppTemplate(e)}>
                                                                     <option value="0">Select Template</option>
                                                                      {
                                                                           whTemplateList && whTemplateList.map((item)=>(
                                                                              <>
                                                                                 <option value={item.id}>{item.title}</option>
                                                                              </>
                                                                              ))
                                                                        }
                                                                  </select>
                                                               </div>
                                                              
                                                               <div className="form-group" style={{'marginTop':'10px'}}>
                                                                  <label for="subject">Follow Up:</label>
                                                                  <div className="col-md-6" style={{'paddingLeft':'0px'}}>
                                                                     <input size="16" type="date" name="followup" className={"form-control"+(isWFollowDateError==false?' invalid':'')} style={{'borderRadius':'0px','paddingLeft':'10px','background':'#fff'}} onChange={(e)=>handleWhatsAppFollowDate(e)}/>
                                                                  </div>
                                                               </div>
                                                               <div className="editormessage">
                                                                  <textarea className={"form-control"+(isWMessageError==false?' invalid':'')} name="message" id="message" rows="3" onChange={(e)=>handleWhatsAppMessage(e)} value={wmessage}></textarea>
                                                               </div>
                                                               <hr/>
                                                               <div className="compose-btn">
                                                                  <button className="btn btn-primary btn-sm" type="submit" name="submit" value="submit" onClick={composeWhatsAppSend}>Send</button>
                                                               </div>
                                                            </div>
                                                         </section>                                                        
                                                   </section>
                                                </div>

                                                   <div className="lead-progress add-append-note testimessage send_msg_details" style={{'display':'none'}}>
                                                      <div className="lead-in-progress-icon lead-status-comment">
                                                         <i className="fa fa-comment"></i>
                                                      </div>
                                                      <section className="mail-box-info">                                                    
                                                            <section className="mail-list mt-0">
                                                               <div className="compose-mail">
                                                                  <div className="form-group">
                                                                     <label for="to" className="">Mobile:</label>
                                                                     <input type="tel" id="lead_phone" name="lead_phone" value={srecieve_id} className={"form-control"+(isSRecieveIDError==false?' invalid':'')} onChange={(e)=>handleSMSTo(e)}/>
                                                                  </div>
                                                                  <div className="form-group" style={{'display':'none'}}>
                                                                     <label for="subject" className="">Subject:</label>
                                                                     <input type="text" id="subject" name="subject" className={"form-control"+(isSSubjectError==false?' invalid':'')} value={ssubject} onChange={(e)=>handleSMSSubject(e)}/>
                                                                  </div>
                                                                   <div className={"form-group"+(isSTemplateIDError==false?' invalid':'')} style={{'display':'block'}}>                                                                    
                                                                     <select type="text" className="form-control" onChange={(e)=>handleSMSTemplate(e)}>
                                                                        <option value="0">Select Template</option>
                                                                         {
                                                                           smsTemplateList && smsTemplateList.map((item)=>(
                                                                              <>
                                                                                 <option value={item.id}>{item.title}</option>
                                                                              </>
                                                                              ))
                                                                        }
                                                                     </select>
                                                                  </div>                                                                
                                                                  <div className="editormessage">
                                                                     <textarea className={"form-control"+(isSMessageError==false?' invalid':'')} name="message" id="message" rows="3" onChange={(e)=>handleSMSMessage(e)} value={smessage}></textarea>
                                                                  </div>
                                                                 
                                                                  <hr/>
                                                                  <div className="compose-btn">
                                                                     <button className="btn btn-primary btn-sm" type="submit" name="submit" value="submit" onClick={composeSMSSend}>Send</button>
                                                                  </div>
                                                               </div>
                                                            </section>                                                           
                                                      </section>
                                                   </div>

                                                <div className="lead-progress send_note_details" style={{'display':'none'}}>
                                                   <div className="lead-in-progress-icon lead-status-comments">
                                                      <i className="fa fa-file-text"></i>
                                                   </div>
                                                   <section className="mail-box-info">
                                                      
                                                         <section className="mail-list mt-0">
                                                            <div className="compose-mail">
                                                               <div className="compose-editor mt-1">
                                                                  <textarea className={"wysihtml5 form-control"+(isNMessageError==false?' invalid':'')} name="message" id="message" rows="2" onChange={(e)=>handleNoteMessage(e)}></textarea>
                                                               </div>
                                                               <hr/>
                                                               <div className="compose-btn">
                                                                  <button className="btn btn-primary btn-sm" type="submit" name="submit" value="submit" onClick={AddLeadNote}>Send</button>
                                                               </div>
                                                            </div>
                                                         </section>
                                                       
                                                   </section>
                                                </div>                                           
                                                  {
                                                      leadFollowInbox && leadFollowInbox.lead_follow_up_inbox.length>0?leadFollowInbox.lead_follow_up_inbox.map((item)=>(
                                                         <>
                                                             <div className="lead-progress">
                                                      <div className="row-d-flex">
                                                         <div className="col">
                                                            <p>
                                                               {item.follow_type==4?'Note':item.subject}
                                                            </p>
                                                         </div>
                                                         <div className="col">
                                                            <p>{item.agent_name}</p>
                                                         </div>
                                                         <div className="col">
                                                            <p>{item.followup_date}</p>
                                                         </div>
                                                      </div>
                                                      <div className="mail-inner">
                                                         <div className="row-d-flex">
                                                            <div className="col">
                                                               <button type="button" data-toggle="tooltip" title="" data-original-title="View Mail" className="btn-mail-open" onClick={()=>MailDetailsToggle(item.id)}><i className="fa fa-arrow-circle-o-down"></i>&nbsp;{item.follow_type==4?'Show Note':item.send_id}</button>
                                                            </div>                                                            
                                                         </div>
                                                      </div>
                                                      <div className={"mail-inner-detail mail_inner_details_"+(item.id)} style={{display:'none'}}>
                                                         <div className="row-d-flex space-col">
                                                            {item.follow_type==4?null:
                                                            <>
                                                            <div className="col">
                                                               <div className="mail-to">To:<a href="#">{item.receive_id}</a>
                                                               </div>
                                                            </div> 
                                                              </>
                                                            }                                                          
                                                         </div>

                                                         <div className="email-temp" dangerouslySetInnerHTML={{ __html: item.message }}>                                                           
                                                         </div>
                                                      </div>
                                                      <div className="lead-in-progress-icon lead-status-mail">
                                                        {item.follow_type==1?<><i className="fa fa-envelope"></i></>:item.follow_type==2?<><i className="fa fa-comment"></i></>:item.follow_type==3?<> <i className="fa fa-comments"></i></>: <i className="fa fa-file-text"></i>} 
                                                      </div>
                                                   </div>  
                                                         </>
                                                           ))
                                                         :null
                                                    
                                                  }
                                                                                                
                                                   
                                                </div>
                                               
                                                
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> 

                           	</section>
                           	</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <Footer/>
   </div>
</div>
</div>
</>
		)
}
export default FollowUp;