import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../layout/footer';
import Master from '../../layout/master';
import Topbar from '../../layout/topbar';
import {company} from '../../validation/validator';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {APP_URL} from '../../validation/basic';
import Loader from '../../loader/loader';
import axios from 'axios';
const Backup=()=>{
	const [permissionlist,setPermissionlist]=useState([]);
    const [accesspermission,setAccesspermission]=useState([]);
    const [menuids,setMenuids]=useState('');
    const [backup,setBackup]=useState([]);
    const [loading,setLoading]=useState(false);
    const [token,setToken]=useState(localStorage.getItem('token'));
    useEffect(()=>{
    	setToken(localStorage.getItem('token'));
    	laodbackupdata();
    },[]);

    const laodbackupdata=async()=>{
    	try{	
    			setLoading(true);
    			const loadbackup=await axios.get(APP_URL+'list-backup-database',{ headers: {"Authorization" : `Bearer ${token}`}});
    			const backup_list = JSON.parse(JSON.stringify(loadbackup.data));
    			if(backup_list.status==1){
    				setBackup(backup_list.data.backuplist);
    				setLoading(false);
    			}

    	}catch(e){
    		return false;
    	}
    }

    const BackupDB=async()=>{
    	if(!window.confirm("Are you sure want to take backup")){
    		return false;
    	}else{
	    	try{
	    		setLoading(true);
	    		const backup_db=await axios.get(APP_URL+'backup-call-database',{ headers: {"Authorization" : `Bearer ${token}`}});
	    		const backup_complete=JSON.parse(JSON.stringify(backup_db.data));
	    		if(backup_complete.status==1){
	    			toast.success('Backup Completed');
	    			setLoading(false);
	    			laodbackupdata();
	    		}else{
	    			toast.error('something went wrong');
	    		}


	    	}catch(e){
	    		toast.error('something went wrong');
	    	}
    	}
    }
	return (
			<>
		<div id="app">
         <div id="main" className="layout-horizontal">
           <Topbar setMenuids={setMenuids} menuids={menuids} setAccesspermission={setAccesspermission} accesspermission={accesspermission} setPermissionlist={setPermissionlist} permissionlist={permissionlist}/>
          <ToastContainer />
  <div className="content-wrapper container">            

<div className="page-content">
    <section className="row">
    	<div className="col-12 col-lg-12">
    		<div className="card">
                <div className="card-header" style={{borderBottom:'1px solid'}}>
                		<h5>Backup Database</h5>		                   
                </div>
                <div className="card-body">
                	<div className="col-md-12 mt-3">                		
                		<button className="btn btn-success" onClick={BackupDB}>Backup</button>
                	</div>
                	<div className="col-md-12 mt-3">
                	<table className="table table-bordered table-hover mb-0">
                		<thead>
                			<tr>
                				<th>Backup</th>
                				<th>Download</th>
                			</tr>
                		</thead>
                		<tbody>
                		{
                			loading==true?<><tr><td colSpan="2"><Loader/></td></tr></>:
			              	<>
			              	{
                			backup && backup.map((item,index)=>(
                				<tr>
	                				<td>{item.name}</td>
	                				<td>
                					{
                                                  permissionlist && permissionlist.map((p,ind)=>(
                                                      <>
                                                      {
                                                        accesspermission.some(item1=>p.id==item1)==true?
                                                        <>                                                          
                                                            
                                                            {p.permission=='Download'?<a href={"http://14.99.232.147:4131/call/public/backup_database/"+item.name} target="_blank" download><button className="btn btn-primary"><i className="fa fa-download"></i></button></a>:null}
                                                    
                                                    </>
                                                    :null
                                                    
                                                }
                                                </>
                                                    ))
                                                }

	                				</td>
                				</tr>
                				))
                			}
                			</>
                		}
                			
                		</tbody>
                	</table>
                	</div>
                </div>
		    </div>
    	</div>
    	
       
        
    </section>
</div>
 <Footer/>
 </div>
          
        </div>
    </div>
			</>
		)
}

export default Backup;